import React from "react";
import jQuery from "jquery";
import { Link } from "react-router-dom";
import "../../assests/styles/Home/business.css";

class Business extends React.Component {
	render() {
		return (
			<div className="business mt-5" >
				<div id="testing" className="section-head pt-4 px-3">
					<h1>Our Establishment</h1>
					<h6>
						We have been consistent with the quality of our products and deal in
						various businesses ranging from poultry feed to processed chicken
					</h6>
				</div>
				<div>
					<NavigationBars />
				</div>
			</div>
		);
	}
}

export default Business;

class NavigationBars extends React.Component {

	render() {
		this.state = {
			Element: "mid",
		};

		function remove() {
			jQuery(".mid").removeAttr("id");
		}

		function add() {
			jQuery(".mid").attr("id", "hover");
		}

		return (
			<div className="image_box px-lg-5 px-3 ">
				<div
					className="box"
					id="first"
					onMouseEnter={() => remove()}
					onMouseLeave={() => add()}
				>
					<div className="mt-3">
						<h4>Equipments</h4>
						<p>
							our diverse portfolio of operations ranging from Grandparents farms to
							large-scale mechanised farming makes us one of the largest vertically
							integrated poultry producers in the world
						</p>

						<Link to="/products?category=Equipments">
							<button>Explore more &#8594;</button>
						</Link>
					</div>
				</div>
				<div
					className="box"
					id="last"
					onMouseEnter={() => remove()}
					onMouseLeave={() => add()}
				>
					<div className="mt-3">
						<h4>Protein</h4>
						<p>
							We know the importance of protein and ensure the usage of top-quality
							products responsible for the same. Skylark aims for complete vertical
							integration by setting up its soya processing unit. Our goal is to
							nurture businesses and meanwhile serve the poultry industry.
						</p>
						<Link to="/products?category=Proteins">
							<button>Explore more &#8594;</button>
						</Link>
					</div>
				</div>
				<div className="box mid" id={this.state.Element === "mid" ? "hover" : ""}>
					<div className="mt-3">
						<h4>Hatchery</h4>
						<p>
							The Hatching eggs are kept in the best possible environment to grow into healthy chicks, Skylark farms rear three generations of birds from grandparent stock to parent stock to commercial stocks.
						</p>

						<Link to="/products?category=Hatchery">
							<button>Explore more &#8594;</button>
						</Link>
					</div>
				</div>

				<div
					className="box"
					id="secondlast"
					onMouseEnter={() => remove()}
					onMouseLeave={() => add()}
				>
					<div className="mt-3">
						<h4>Food</h4>
						<p>
							Skylark believes in the quality of food products resulting in healthy and
							full of necessary nutrition. These beneficial nutrients aid in growth.
							Skylark has set up a processing unit with various products, including
							fresh chicken, frozen chicken, and Ready to Eat products.
						</p>

						<Link to="/products?category=Food">
							<button>Explore more &#8594;</button>
						</Link>
					</div>
				</div>
				<div
					className="box"
					id="second"
					onMouseEnter={() => remove()}
					onMouseLeave={() => add()}
				>
					<div className="mt-3">
						<h4>Feed</h4>
						<p>
							Our poultry feed is formulated with high-quality vitamins, minerals, and
							grains to fulfill the surging demand for feed in the poultry industry. We
							have our testing labs to ensure the raw material’s quality is according
							to our requirements.
						</p>

						<Link to="/products?category=Feed">
							<button>Explore more &#8594;</button>
						</Link>
					</div>
				</div>
			</div>
		);
	}
}
