import React, { useState, useEffect } from "react";
import "../../assests/styles/product/product.css";
import Slider from "react-slick";
import gitbranch from "../../assests/images/GitBranch.png";
import chartbar from "../../assests/images/ChartBar.png";
import chartpie from "../../assests/images/ChartPieSlice.png";
import rightArrow from "../../assests/images/right-green-arrow.png";
import leftArrow from "../../assests/images/left-green-arrow.png";
import NextGreen from "../../assests/images/nextgreen.png";
import UpArrow from "../../assests/images/up-arrow.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import productsData from "./productsData";
import { Link, useLocation } from "react-router-dom";

const Productcart = () => {

	const search = useLocation().search;
	const category = new URLSearchParams(search).get('category');

	const [selectedCategoryData, setselectedCategoryData] = useState("");

	const changeCategoryData = (category) => {
		const SelectedData = productsData.filter((el) => {
			return el.category === category;
		});

		let subcategoryData = [];
		SelectedData[0].subcategories.forEach((subcategory) => {
			let arr = SelectedData[0].products.filter((el) => {
				return el.subCategory === subcategory;
			});
			let item = {
				[subcategory]: {
					NoOfproducts: arr.length,
					products: arr,
				},
			};
			subcategoryData.push(item);
		});

		let NewState = {
			category: SelectedData[0].category,
			desc: SelectedData[0].desc,
			NoOfSubcategories: SelectedData[0].subcategories.length,
			totalProducts: SelectedData[0].products,
			subcategoryProducts: subcategoryData,
			productsLength: SelectedData[0].products.length,
		};

		setselectedCategoryData(NewState);
	};

	const setActiveCategory = (event) => {
		document.querySelectorAll(".category-items .category").forEach((element) => {
			element.classList.remove("active-category");
		});

		event.target.classList.add("active-category");
	};

	function testing() {
		document.querySelector("html").style.scrollBehavior = "smooth";
		setTimeout(() => {
			const position = document.querySelector(".productCart").offsetTop;
			window.scrollTo(0, position - 80);
		}, 300);
		setTimeout(() => {
			document.querySelector("html").style.scrollBehavior = "initial";
		}, 700);
	}

	useEffect(() => {
		if (category) {
			document.getElementById(category).click();
		} else {
			changeCategoryData("Feed");
		}
	}, []);

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		nextArrow: <img src={rightArrow} alt="" />,
		prevArrow: <img src={leftArrow} alt="" />,
		responsive: [
			{
				breakpoint: 990,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},

			{
				breakpoint: 450,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	const redirectToUrl = (data) => {
		if (data.url) {
			var a = document.createElement('a');
			a.target = '_blank';
			a.href = data.url;
			a.click();
			a.remove();
		}
	}

	return (
		<div className="container mt-5 productCart">
			<div className=" pt-5 px-3 mt-4 mt-md-5">
				<div className="section-head" data-aos="fade-up">
					<h1>Our Product Categories</h1>
				</div>

				<div
					className="category-items d-flex justify-content-between justify-content-md-center align-items-center mt-5"
					data-aos="fade-up"
				>
					<div id="Feed"
						className="category active-category px-2 px-md-5"
						onClick={(e) => {
							testing();
							changeCategoryData("Feed");
							setActiveCategory(e);
						}}
					>
						<img src={chartpie} alt="" />
						<span>Feed</span>
					</div>
					<div id="Hatchery"
						className="category px-2 px-md-5"
						onClick={(e) => {
							testing();
							changeCategoryData("Hatchery");
							setActiveCategory(e);
						}}
					>
						<img src={"/icons/bank.png"} alt="" />
						<span>Hatchery</span>
					</div>

					<div id="Equipments"
						className="category px-2 px-md-5"
						onClick={(e) => {
							testing();
							changeCategoryData("Equipments");
							setActiveCategory(e);
						}}
					>
						<img src={chartbar} alt="" />
						<span>Equipments</span>
					</div>
					<div id="Proteins"
						className=" category px-2 px-md-5"
						onClick={(e) => {
							testing();
							changeCategoryData("Proteins");
							setActiveCategory(e);
						}}
					>
						<img src={gitbranch} alt="" />
						<span>Proteins</span>
					</div>
					<div id="Food"
						className="category px-2 px-md-5"
						onClick={(e) => {
							testing();
							changeCategoryData("Food");
							setActiveCategory(e);
						}}
					>
						<img src={chartpie} alt="" />
						<span>Foods</span>
					</div>
				</div>
			</div>

			{selectedCategoryData && (
				<div className="product-section mt-5  pr-text">
					<h3 className="text-black text-center pt-5" data-aos="fade-up">
						{selectedCategoryData.category}
					</h3>
					<p className="px-2 text-center" data-aos="fade-up">
						{selectedCategoryData.desc}
					</p>
					{selectedCategoryData.subcategoryProducts &&
						selectedCategoryData.subcategoryProducts.map((subCategory) => {
							return (
								<div
									key={Object.keys(subCategory)[0]}
									className="text-center mt-5 pt-4"
									data-aos="fade-up"
								>
									<h5 className="text-black">{Object.keys(subCategory)[0]}</h5>

									<Slider
										{...settings}
										slidesToShow={
											subCategory[Object.keys(subCategory)[0]].NoOfproducts < 4
												? subCategory[Object.keys(subCategory)[0]].NoOfproducts
												: 4
										}
										className={`mt-md-5 mt-4 ${subCategory[Object.keys(subCategory)[0]].NoOfproducts < 4
											? "centerProducts"
											: ""
											}`}
									>
										{subCategory[Object.keys(subCategory)[0]].products.map((prod) => {
											return (
												<div key={prod.productId}>
													<Link
														to={`/productdetails/${selectedCategoryData.category}/${prod.Data.productName}`}
													>
														<div className="product-cart">
															<div className="product_img">
																<img
																	src={`./images/products/${prod.Data.product_img}`}
																	alt=""
																/>
															</div>
															<div className="px-4 pb-4 product-cart-content">
																<div className="mt-3 text-black mn-h-3">
																	<h5>{prod.Data.productName}</h5>
																</div>
																<div className="mt-2 product-cart_desc oy" dangerouslySetInnerHTML={{ __html: prod.Data.desc }}>
																</div>
																<div className=" mt-2">
																	<button className="bg-transparent border-0 pr-color px-0 d-flex align-items-center gap-2">
																		Enquiry{" "}
																		<img
																			src={NextGreen}
																			alt=""
																			style={{
																				width: "18px",
																				position: "relative",
																				top: "1.5px",
																			}}
																		/>
																	</button>
																</div>
															</div>
														</div>
													</Link>
												</div>
											);
										})}
									</Slider>
								</div>
							);
						})}

					{!selectedCategoryData.subcategoryProducts.length && (
						<Slider
							{...settings}
							slidesToShow={
								selectedCategoryData.totalProducts.length < 4
									? selectedCategoryData.totalProducts.length
									: 4
							}
							data-aos="fade-up"
							className={`mt-md-5 mt-4 ${selectedCategoryData.totalProducts.length < 4 ? "centerProducts" : ""
								}`}
						>
							{selectedCategoryData.totalProducts.map((product) => {
								return (
									<div key={product.productId}>
										<div className="product-cart">
											<div className="product_img" onClick={() => { redirectToUrl(product.Data) }}>
												<img
													src={`./images/products/${product.Data.product_img}`}
													alt=""
												/>
											</div>
											<div className="px-4 pb-4  product-cart-content">
												<div className="mt-3 text-black">
													<h6>{product.Data.productName}</h6>
												</div>
												<div className="mt-2 product-cart_desc oy" dangerouslySetInnerHTML={{ __html: product.Data.desc }}>
												</div>
												<div className=" mt-2">
													<button
														onClick={() => { redirectToUrl(product.Data) }}
														className="bg-transparent border-0 pr-color px-0 d-flex align-items-center gap-2">
														Enquiry{" "}
														<img
															src={NextGreen}
															alt=""
															style={{
																width: "18px",
																position: "relative",
																top: "1.5px",
															}}
														/>
													</button>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</Slider>
					)}
				</div>
			)}

			<div className="my-5 text-center">
				<h5
					className=" backToTop font-font-weight-bold"
					onClick={() => {
						window.scrollTo({ top: 0, behavior: 'smooth' });
					}}
				>
					Back to Top <img src={UpArrow} alt="" className="ml-2" />
				</h5>
			</div>
		</div>
	);
};

export default Productcart;
