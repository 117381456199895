import React, { useState, useEffect } from "react";
import Banner from "../components/Career/Banner";
import CurrentHoldings from "../components/Career/currentholdings";
import Form from "../components/Career/form";
import FooterPage from "../components/Footer";
import NavBar from "../components/Navbar";

const CareerPage = () => {
  const [formPopUp, setformPopUp] = useState(false);
  const openPopUp = () => {
    setformPopUp(true);
  };
  const closePopUp = () => {
    setformPopUp(false);
  };

  useEffect(() => {
    if (formPopUp) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [formPopUp]);

  return (
    <>
      <NavBar />
      {formPopUp && <Form closePopUp={closePopUp} />}
      <div className="bg-primary-gradient">
        <div className="container px-4 px-lg-5">
          <Banner />
          <CurrentHoldings openPopUp={openPopUp} />
          <div
            className="mt-4 mt-md-5 py-5 text-center"
            data-aos="flip-up"
            data-aos-duration="700"
            data-aos-easing="ease-in"
          >
            <h6>Looking for something else..Let us Know </h6>
            <h1 className="pr-color pb-3">careers@skylarkhatcheries.com</h1>
          </div>
        </div>
      </div>
      <FooterPage />
    </>
  );
};

export default CareerPage;
