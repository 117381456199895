import React from "react";
import "../../assests/styles/career/careerForm.css";
import Close from "../../assests/images/navClose.png";
import emailjs from '@emailjs/browser';

const Form = ({ closePopUp }) => {

  const form = React.useRef();
  var currentSelectedValue = localStorage.getItem('ApplyNow');
  const [imageUrl, SetImageUrl] = React.useState("");
  const [loading, setUploading] = React.useState('');

  const CLOUDNAME = "dx4nkijib";

  const validateSize = async (input) => {
    const fileSize = input.files[0].size / 1024; // in KB
    if (fileSize > 2000) {
      alert('File size not to exceeds 2 MB');
      return;
    } else {
      uploadImage(input.files[0]);
    }
    return;
  };

  const uploadImage = (file) => {
    setUploading("Resume uploading");

    const Data = new FormData();
    Data.append('file', file);
    Data.append('upload_preset', 'career_resume');
    fetch(`https://api.cloudinary.com/v1_1/${CLOUDNAME}/upload`, {
      method: 'POST',
      body: Data
    }).then(async (res) => {
      const result = await res.json();

      var url = result.secure_url;
      var array = url.split('.');

      if(array[array.length - 1] === 'pdf') {
        array[array.length - 1] = 'png';
      }

      url = array.join('.');

      SetImageUrl(url);
      setUploading("Resume Uploaded");
    }).catch((err) => {
      console.log(err)
      alert('Something went wrong while uploading the resumes file');
      setUploading("Error On Upload");
    })
  }

  const sendEmail = (e) => {
    e.preventDefault();

    const currentForm = form.current
    const postData = {};
    postData['FullName'] = currentForm['FullName'].value;
    postData['contactNo'] = currentForm['contactNo'].value;
    postData['inputEmail4'] = currentForm['inputEmail4'].value;
    postData['JobSelection'] = currentForm['JobSelection'].value;
    postData['inputAddress'] = currentForm['inputAddress'].value;
    postData['noticePeriod'] = currentForm['noticePeriod'].value;
    postData['link'] = imageUrl;

    console.log(postData);

    emailjs.send('service_khsidk9', 'template_uddjhm5', postData, '4SxzU1a09DFe232ww')
      .then((result) => {
        closePopUp(true);
        alert('Application recived');
      }, (error) => {
        alert('Something went wrong');
      });
  }

  return (
    <div className="row">
      <div className="career-form px-4 py-5 p-md-5">
        <img src={Close} alt="" className="formClose" onClick={closePopUp} />
        <form ref={form} onSubmit={sendEmail}>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label className="pr-color" for="FullName">
                Full Name
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="Name"
                name="FullName"
                required
              />
            </div>
            <div class="form-group col-md-6">
              <label className="pr-color" for="contactNo">
                Contact No.
              </label>
              <input
                type="text"
                class="form-control"
                name="contactNo"
                placeholder="5635XXXXXX"
                required
              />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-6">
              <label className="pr-color" for="inputEmail4">
                Email
              </label>
              <input
                type="email"
                class="form-control"
                name="inputEmail4"
                placeholder="Email"
                required
              />
            </div>
            <div class="form-group col-md-6">
              <label className="pr-color" for="JobSelection">
                Job Selection
              </label>

              <input
                type="text"
                class="form-control"
                name="JobSelection"
                placeholder=""
                value={currentSelectedValue}
                required
              />
            </div>
          </div>
          <div class="form-group">
            <label className="pr-color" for="inputAddress">
              Current working location
            </label>
            <input
              type="text"
              class="form-control"
              name="inputAddress"
              placeholder="ABC Main Street"
              required
            />
          </div>

          <div class="form-row">
            <div class="form-group col-md-6">
              <label className="pr-color" for="noticePeriod">
                Notice Period
              </label>
              <input type="text" class="form-control" name="noticePeriod" required />
            </div>
          </div>

          {<div class="custom-file  form-group mt-3 ">
            <input
              type="file"
              class="custom-file-input"
              name="customFile"
              onChange={(e) => { validateSize(e.target) }}
              required
              accept="application/*,image/*" />
            <label className="pr-color custom-file-label" for="customFile">
              Upload Resume (below 2 MB)
            </label>
          </div>}
          <p>{loading}</p>

          <div className="text-center mt-5">
            <button type="submit" className="btn pr-color-bg text-white">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div >

  );
};

export default Form;
