import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assests/styles/Home/parivar.css";
import RishiPalPoultry from "../../assests/Farmer/Rishipal.png";
import NilkanthaLayerFarm from "../../assests/Farmer/NilkanthaLayerFarm.png";
import LotusAgro from "../../assests/Farmer/LotusAgro.png";
import rightArrow from "../../assests/images/right-green-arrow.png";
import leftArrow from "../../assests/images/left-green-arrow.png";
import AnimatedNumber from "animated-number-react";
import VisibilitySensor from 'react-visibility-sensor';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const parivar = () => {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: false,
		pauseOnHover: false,
		pauseOnFocus: false,
		autoplaySpeed: 3000,
		nextArrow: <img src={rightArrow} alt="" />,
		prevArrow: <img src={leftArrow} alt="" />,
	};

	const formatValue = (value) => {
		return value.toFixed(0);
	}

	return (
		<>
			<div style={{ background: "#eff9f8" }} className="MainSlider">
				<Slider {...settings}>
					<div className="container px-3 my-3 d-block-m-auto" >
						<div className="carousel-heading">
							<h1>Our Happy Farmers</h1>
						</div>
						<div className="carousel-description justify-content-center flex-column flex-lg-row gap-5">
							<div className="col-auto text-center">
								<a href="https://www.youtube.com/watch?v=dnLyKpw-HUg" target="_blank" rel="noreferrer">
									<img alt="" src={LotusAgro} />
								</a>
							</div>

							<div className="carousel-sub-description col-lg-6 text-center text-lg-start px-3 px-md-0">
								<h2>Lotus Agro Tech</h2>
								<h5 style={{ color: "#4B5159" }}>Bihar</h5>
								<div className="sub-text">
									<p>
										I have been using skylark feed for the last 5 years and have received consistently good results. The birds are healthy and their eggs are always a consistent quality. I would recommend this feed to anyone who is looking to raise healthy and productive birds.
									</p>
								</div>
								<div className="stats">
									<div className="years">
										<VisibilitySensor>
											{({ isVisible }) =>
												<div>{isVisible ?
													<h2>
														<AnimatedNumber
															value={98}
															formatValue={formatValue}
															duration={1500}
															delay={10}
														/>{'%'}
													</h2>
													: 'null'}
												</div>
											}
										</VisibilitySensor>
										<h4>Peak Production</h4>
									</div>
									<div className="percent">
										<VisibilitySensor>
											{({ isVisible }) =>
												<div>{isVisible ?
													<h2>
														<AnimatedNumber
															value={108}
															formatValue={formatValue}
															duration={1500}
															delay={10}
														/>{' Week'}
													</h2>
													: 'null'}
												</div>
											}
										</VisibilitySensor>
										<h4>Flock Age</h4>
									</div>
									<div className="farms">
										<VisibilitySensor>
											{({ isVisible }) =>
												<div>{isVisible ?
													<h2>
														<AnimatedNumber
															value={5000}
															formatValue={formatValue}
															duration={1500}
															delay={10}
														/>
													</h2>
													: 'null'}
												</div>
											}
										</VisibilitySensor>
										<h4>Flock Size</h4>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="container px-3 my-3">
						<div className="carousel-heading">
							<h1>Our Happy Farmers</h1>
							{/* <p>lopem epsum</p> */}
						</div>
						<div className="carousel-description justify-content-center flex-column flex-lg-row gap-5">
							<div className="col-auto text-center">
								<a href="https://www.youtube.com/watch?v=QRoLhhWgZ5w&t" target="_blank" rel="noreferrer">
									<img alt="" src={NilkanthaLayerFarm} />
								</a>
							</div>

							<div className="carousel-sub-description col-lg-6 text-center text-lg-start px-3 px-md-0">
								<h2>Nilkantha Layer Farm</h2>
								<h5 style={{ color: "#4B5159" }}>Assam</h5>
								<div className="sub-text">
									<p>
										Its been more than a year being associated with Skylark, and in this period skylark has proven its tagline 'Quality is our endeavour'. Skylark Poultry Feed has been my go-to brand for all of my poultry feed needs since I started getting into it last year.
									</p>
								</div>
								<div className="stats">
									<div className="years">
										<VisibilitySensor>
											{({ isVisible }) =>
												<div>{isVisible ?
													<h2>
														<AnimatedNumber
															value={96.99}
															formatValue={formatValue}
															duration={1500}
															delay={10}
														/>{'%'}
													</h2>
													: 'null'}
												</div>
											}
										</VisibilitySensor>
										<h4>Peak Production</h4>
									</div>
									<div className="percent">
										<VisibilitySensor>
											{({ isVisible }) =>
												<div>{isVisible ?
													<h2>
														<AnimatedNumber
															value={34}
															formatValue={formatValue}
															duration={1500}
															delay={10}
														/>{' Week'}
													</h2>
													: 'null'}
												</div>
											}
										</VisibilitySensor>
										<h4>Flock Age</h4>
									</div>
									<div className="farms">
										<VisibilitySensor>
											{({ isVisible }) =>
												<div>{isVisible ?
													<h2>
														<AnimatedNumber
															value={18000}
															formatValue={formatValue}
															duration={1500}
															delay={10}
														/>
													</h2>
													: 'null'}
												</div>
											}
										</VisibilitySensor>
										<h4>Flock Size</h4>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="container px-3 my-3">
						<div className="carousel-heading">
							<h1>Our Happy Farmers</h1>
						</div>
						<div className="carousel-description justify-content-center flex-column flex-lg-row gap-5">
							<div className="col-auto text-center">
								<a href="https://www.youtube.com/watch?v=QRoLhhWgZ5w&t" target="_blank" rel="noreferrer">
									<img alt="" src={RishiPalPoultry} />
								</a>
							</div>

							<div className="carousel-sub-description col-lg-6 text-center text-lg-start px-3 px-md-0">
								<h2>Rishi Pal Poultry</h2>
								<h5 style={{ color: "#4B5159" }}>Haryana</h5>
								<div className="sub-text">
									<p>
										I am using skylark chicks from a year now and had also started using skylark feed, Only a good quality feed like skylark can give you an unexpected production which i had observed with my flock. I also recommend everyone to use skylark feed and skylark chicks.
									</p>
								</div>
								<div className="stats">
									<div className="years">
										<VisibilitySensor>
											{({ isVisible }) =>
												<div>{isVisible ?
													<h2>
														<AnimatedNumber
															value={99.8}
															formatValue={formatValue}
															duration={1500}
															delay={10}
														/>{'%'}
													</h2>
													: 'null'}
												</div>
											}
										</VisibilitySensor>
										<h4>Peak Production</h4>
									</div>
									<div className="percent">
										<VisibilitySensor>
											{({ isVisible }) =>
												<div>{isVisible ?
													<h2>
														<AnimatedNumber
															value={34}
															formatValue={formatValue}
															duration={1500}
															delay={10}
														/>{' Week'}
													</h2>
													: 'null'}
												</div>
											}
										</VisibilitySensor>
										<h4>Flock Age</h4>
									</div>
									<div className="farms">
										<VisibilitySensor>
											{({ isVisible }) =>
												<div>{isVisible ?
													<h2>
														<AnimatedNumber
															value={13000}
															formatValue={formatValue}
															duration={1500}
															delay={10}
														/>
													</h2>
													: 'null'}
												</div>
											}
										</VisibilitySensor>
										<h4>Flock Size</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Slider>
			</div>
		</>
	);
};

export default parivar;
