import React, { Component } from "react";
import "../../assests/styles/About/head.css";

class Head extends Component {
	render() {
		return (
			<>
				<div className="py-5 about-header section-head ">
					<h1 className="text-white right-heading line-1 typewrite">This is Skylark</h1>
				</div>
				<div
					className="content col-md-8 mx-auto my-5 py-md-5 text-center"
					data-aos="fade-up"
				>
					<p className="text-start">
						Skylark Hatcheries was established by Mr. Jagbir Singh Dhull and Mr.
						Jasbir Singh Deswal in 1985. Today, the Skylark Group is one of the
						largest poultry producers in India. Through an unwavering commitment
						towards quality, excellence and strategic integration, we have built a
						diverse portfolio of operations that include grandparent stock, parent
						stock, hatcheries, broiler contract farming, feed production, poultry
						processing, large-scale mechanised farming and equipment fabrication.
					</p>
					<p className="text-start mt-md-2 mt-3">
						Our robust network of stakeholders helps us deliver on our promise of
						providing fresh, healthy, hygienic and wholesome poultry products for our
						customers, at affordable costs. Our facilities, spread across India, are
						equipped with state-of-the-art equipment and world-class processes.
					</p>
				</div>
			</>
		);
	}
}
export default Head;
