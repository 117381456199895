import React from "react";
import "../../assests/styles/Home/global.css";
import Ghana from "../../assests/images/Flag_of_Ghana.png";
import Myanmar from "../../assests/images/Flag_of_Myanmar.png";
import Srilanka from "../../assests/images/Flag_of_Sri_Lanka.png";
import Nepal from "../../assests/images/Flag_of_Nepal.png";
import GhanaBg from "../../assests/images/Ghana.png";
import SrilankaBg from "../../assests/images/sri-lanka.png";
import NepalBg from "../../assests/images/Nepal.png";
import MyanmarBg from "../../assests/images/myanmar.png";

class Global extends React.Component {
	state = {
		global: []
	};

	componentDidMount() {
		const postData = [
			{
				name: 'Park Agrotech Pvt. Ltd',
				bgImage: GhanaBg,
				flag: Ghana,
				country: 'Ghana',
				desc: `An innovative step to restructure and modernize the poultry fields of
				Ghana. We intend to provide the solution to improve and provide healthy
				food products that were carried out after an emerging joint venture of
				Park Agrotech Pvt. Ltd. with Skylark Hatcheries in 2015.`
			},
			{
				name: 'Premix Plant Srilanka',
				bgImage: SrilankaBg,
				flag: Srilanka,
				country: 'Srilanka',
				desc: `A solution-oriented step to reorient and modernize Ghana's poultry industry has been achieved 
				following an emerging joint venture by Park Agrotech Pvt. ltd.
				 with Skylark Hatcheries in 2015`
			},
			{
				name: 'SKYLARK ARGO PVT. LTD.',
				bgImage: NepalBg,
				flag: Nepal,
				country: 'Nepal',
				desc: `Skylark Agro Pvt. Ltd. is one of the leading poultry feed manufacturers in Nepal. We are committed to providing the best quality poultry products to the Nepali market. Our poultry feed range is a result of years of research and development, and is made from high-quality ingredients.`
			},
			{
				name: 'Park Agrotech Pvt. Ltd',
				bgImage: MyanmarBg,
				flag: Myanmar,
				country: 'Myanmar',
				desc: `Myanmar has proved to be a health-conscious region being in business
				with Skylark Company Ltd. for the past several decades. Our quality of
				products has helped us earn the credibility of our business in Myanmar
				and several other countries worldwide and has kept us motivated to
				maintain the quality with ease and enhance our business.`
			}
		];

		this.setState({ global: postData });
	}

	render() {
		return (
			<div className="global-main container my-2 mt-3 py-3 py-md-2 ">
				<div className="section-head pb-2">
					<h1 >Our Global Family</h1>
					<h4 >
						We celebrate four decades of victorious and glorious existence as a
						business committed to global health.
					</h4>
				</div>
				<div className="global-row">
					{this.state.global && this.state.global.map((el) => {
						return (
							<div className="global-column mx-2 px-3"  key={el.country}>
								<div className=" global-card">
									<div className="card-image">
										<img src={el.bgImage} className="bg-map" alt={el.country} />
									</div>
									<h3>{el.name}</h3>
									<div className="mt-3 flag-text">
										<img src={el.flag} alt={el.country} />
										{el.country}
									</div>
									<p className="mt-4">
										{el.desc}
									</p>
								</div>
							</div>
						)
					})}
				</div>
			</div>
		);
	}
}

export default Global;
