import React from "react";
import "../../assests/styles/Home/director.css";
import Director_Image from "../../assests/images/Director.png";
import Quote from "../../assests/images/Quote.png";

class Director extends React.Component {
  render() {
    return (
      <div className="director relative">
        <div className="director-heading column" >
          <h2 style={{ color: "#2C323C" }}>Word from the director</h2>
        </div>
        <div className="director-content  column">
          <div
            className="director-left  column"
            
            data-aos-delay="400"
          >
            <img src={Quote} alt="" className="quote d-none d-md-block" />
            <h2>Mr. Jagbir Singh Dhull</h2>
            <h4>MD, Skylark Hatcheries</h4>
            <p>
              One word that summarizes Skylark is Passion - Passion for Quality,
              Passion for Excellence, and Passion for Progress. Our passion
              encouraged us to take the initiative and sustain in this business
              for 36 years.
            </p>
            <p>
              Quality at Skylark is not just a word but a motto embedded across
              our processes and operations. We have always worked to be a
              quality leader in India's poultry sector, which makes us different
              from our contemporaries.
            </p>
            <p>
              Quality and credibility go hand in hand. One is necessary for
              attaining the other. Our commitment to our business is essential
              to excellence at Skylark.
            </p>
            <p>
              When we talk about progress and growth, we do not mean it just for
              Skylark but also the farming and business fraternity. We have
              values and credibility in the market today because our products
              have ensured high profits for everyone due to their peerless
              quality.
            </p>
          </div>
          <div className="director-right  column" >
            <img alt="DirectorImage" src={Director_Image} />
          </div>
        </div>
      </div>
    );
  }
}

export default Director;
