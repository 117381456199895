import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import HomePage from "./pages/Home";
import ContactPage from "./pages/Contact";
import ProductPage from "./pages/product";
import AboutUs from "./pages/AboutUs";
import Productdetails from "./components/product/productdetails";
import Sustainibility from "./components/Sustainibility/index";
import CareerPage from "./pages/Career";
import ScrollToTop from "./components/scrollToTop";
import Aos from "aos";
import "aos/dist/aos.css";
import "./App.css";


class App extends Component {

  state = {
    loading: true
  };

  componentDidMount() {
    this.demoAsyncCall().then(() => this.setState({ loading: false }));

    Aos.init({
      duration: 1000,
      easing: "ease-in-out",
    });
  };

  demoAsyncCall() {
    return new Promise((resolve) => setTimeout(() => resolve(), 1500));
  }


  render() {
    if (this.state.loading) {
      return (
        <div className="loadingBar">
          <div></div>
        </div>
      );
    }
    return (
      <BrowserRouter>
        <ScrollToTop>
          <Switch >
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route exact path="/contact">
              <ContactPage />
            </Route>
            <Route exact path="/career">
              <CareerPage />
            </Route>
            <Route exact path="/about">
              <AboutUs />
            </Route>
            <Route exact path="/products">
              <ProductPage />
            </Route>
            <Route exact path="/productdetails/:category/:productId">
              <Productdetails />
            </Route>
            <Route exact path="/sustainability">
              <Sustainibility />
            </Route>
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default App;
