const productsData = [
  {
    category: "Feed",
    desc: "Healthy and nutritious feed produced by our feed mills is currently the most preferred diet in the Indian poultry and livestock market. The different types of feed we produce are optimally tailored to the different requirements of livestock and poultry.",
    subcategories: ["Cattle Feed", "Broiler Feed", "Layer Feed"],
    products: [
      {
        subCategory: "Cattle Feed",
        Data: {
          product_img: "Feed/Cattle/Calf Starter.png",
          productName: "Calf Starter",
          desc: "Calf starter helps transit the calf from the period of milk feeding to the period of dry feeding. Calf starter is crucial to the development of healthy rumen, growth of the body, and weaning of the calf. The Calf starter should be nutritious and palatable. Urea is not a starter feed as it is tough for the calf to digest urea. The feed meets protein, energy, vitamins, and minerals requirements. This feed should be given to the calf around the 5th day in the shallow bucket. You should start with just a handful and increase the quantity gradually.",
        },
        productId: 1,
      },
      {
        subCategory: "Cattle Feed",
        Data: {
          product_img: "Feed/Cattle/Calf Grower.png",
          productName: "Calf Grower",
          desc: "The Calf Grower feed is designed exclusively to be fed to the dry cows and to replace heifers. Calf Grower is the block of high density, allowing supplementation of essential nutrients. Grower feed helps in healthy rumen development as this helps the calf convert to heifers. It is also better suited for a diet high in fiber to absorb the nutrition. Calves transitioning to heifers should be given between 12-13 weeks for best results. It should regularly feed around 10 pounds a day.",
        },
        productId: 2,
      },

      {
        subCategory: "Cattle Feed",
        Data: {
          product_img: "Feed/Cattle/Heifer.png",
          productName: "Heifer",
          desc: "Our product provides a complete diet for heifers at all stages of life. Heifers should be fed diets with appropriate protein to meet live weight targets. Heifer weight gain is the best sign of meeting nutritional requirements and adequate minerals and water provision. Farmers should focus on meeting actual weight for age live weight targets for heifers rather than a particular pattern of weight gain. Energy and protein are required for maintenance and growth in heifers' diets. Well-grown heifers will produce more milk, compete better with mature cows and survive longer in the herd.",
        },
        productId: 3,
      },

      {
        subCategory: "Cattle Feed",
        Data: {
          product_img: "Feed/Cattle/Trans Dry.png",
          productName: "Trans Dry",
          desc: "Trans dry feed is a neglected part of the dairy herd. Trans dry feed is essential to reaching the best high milk yields, good herd health, fertility, and animal welfare. As a result, we have produced various products to suit different conditions. Our motive is to calve cows down in a situation to reach their yield potential in the subsequent lactation. In the early dry period, provide a trans dry diet to maintain body condition score by controlling access to forage and providing minerals and ad-lib straw. Forages should be more diminutive in both potassium and calcium.",
        },
        productId: 4,
      },

      {
        subCategory: "Cattle Feed",
        Data: {
          product_img: "Feed/Cattle/Trans Closeup.png",
          productName: "Trans Pre-Milking",
          desc: "Beginning on the day after birth, trans-pre-milking is fed to calves. Traditionally milk was fed after morning and evening, milking at 4 L per day. This feeding rate has also been applied to trans pre-milking feed. Trans pre-milking feed is a milk-based powder that must be reconstituted to approximately 10% - 15% solids. More recently, milk and trans-pre-milking feeding rates have increased to 6 L for Holsteins. New technology has developed where calves are allowed to suckle essentially at will. Upwards of 10 L per day is familiar with trans pre-milking feed.",
        },
        productId: 5,
      },

      {
        subCategory: "Cattle Feed",
        Data: {
          product_img: "Feed/Cattle/Trans Fresh.png",
          productName: "Trans Fresh",
          desc: "Trans fresh is a high-quality, all-natural 17% pelleted protein product. Trans fresh contains a blend of selected ingredients to optimize rumen function, vitamins, and minerals to help assure a healthy start when cows enter lactation. It helps to develop a healthy rumen before calving. The feed is highly digestible to promote feed intake. Trans fresh contains organic trace minerals readily absorbed, stored, and utilized by the animal. This is critical when trace mineral antagonists are present, or cows are under stress.",
        },
        productId: 6,
      },

      {
        subCategory: "Cattle Feed",
        Data: {
          product_img: "Feed/Cattle/Buff Special.png",
          productName: "Buff Special",
          desc: "Buff special feed is a high-quality protein formulated with vitamins and minerals to ensure performance and boost immunity. The best-seller feed contains high digestive fiber feed for ten-liter milk yields. Buff special feed enhances fiber digestibility, which positively affects milk production. The feed optimizes the mid to late lactation curve. This feed helps to boost immunity. Buff special feed improves Somatic Cell Count (SCC) and fertility.",
        },
        productId: 7,
      },

      {
        subCategory: "Broiler Feed",
        Data: {
          product_img:
            "Feed/Poultry/Broiler/Skylark/1. Broiler Pre-Starter Crumbs.png",
          productName: "Broiler Pre-starter",
          desc: "A healthy diet plays a vital role in the development of a chick. An effective Broiler pre-starter feed during the initial seven days post-hatching is essential. It offers an additional boost to the young chick they need in their early life. The first 3-5 days represent the most significant and crucial proportion of the broiler's life. So, whatever the broiler eats during these days will impact the future and health of the broiler. The Broiler pre-starter feed aims for the broiler's nutritional need and supports the transition.",
        },
        productId: 8,
      },

      {
        subCategory: "Broiler Feed",
        Data: {
          product_img:
            "Feed/Poultry/Broiler/Skylark/2. Broiler Starter Crumbs.png",
          productName: "Broiler Starter",
          desc: "Chicks develop and proliferate during the initial 2 weeks after hatching. They get double in weight, and their digestive tract attains the required size for supporting the bones and developing muscles. The Broiler starter feed offers various nutrients that assure the proper growth of the chick. To overcome the digestive tract issues, a feed is essential that can help cultivate all the nutrition and minerals to the chick. Hence, a Broiler starter feed was created, enhancing the chick's performance. It makes a real & significant difference.",
        },
        productId: 9,
      },

      {
        subCategory: "Broiler Feed",
        Data: {
          product_img:
            "Feed/Poultry/Broiler/Skylark/3. Broiler Finisher Pellets.png",
          productName: "Broiler Finisher",
          desc: "The Broiler Finisher Feed offers the high-energy feed, which is developed as the finishing feed for the broilers. The feed is mainly provided in the meal, which is meant for high consumption and intake. The Broiler Finisher Feed is primarily fed to chickens for 30 days. It is given for the next 8 days, which means that the feed should be given until the chicken reaches 38 days. All the birds will take approximately 1 Kg of the Broiler Finisher Feed, which is in the pellet form.",
        },
        productId: 10,
      },

      {
        subCategory: "Layer Feed",
        Data: {
          product_img: "Feed/Poultry/Layer/1. Layer Chick Crumbs.png",
          productName: "Layer Chick",
          desc: "The best chicken feed for the Layers mainly depends on the goals of the flock. You need to choose the complete layer feed before the start of the transition. Ideally, you can decide on layer feed by the 16th week. With this, the chick will be ready for growth once it reaches the 18th week. The Layer Chick feed should consist of every essential and crucial nutrient necessary for every hen laying eggs without needing any supplement. You should choose the Layer Chick feed that will keep the hens healthy and strong.",
        },
        productId: 11,
      },

      {
        subCategory: "Layer Feed",
        Data: {
          product_img: "Feed/Poultry/Layer/2. Layer Grower Crumbs.png",
          productName: "Layer Grower",
          desc: "The Layer Grower feed is similar to the chicken feed for the teenage chooks. However, dietary needs for the layer between 6-20 weeks are quite different from baby chick or layer. Quite significantly, the Layer Grower feed consists of the protein component, which is almost 16-18%; however, it has less calcium than the regular layer feed. Layer Grower feed helps the continued and tremendous growth of the chick without bombarding them with supplements. It helps with the proper growth of the chick. Once your hen starts laying eggs, it ensures that they are now all set for the Layer Grower feed.",
        },
        productId: 12,
      },

      {
        subCategory: "Layer Feed",
        Data: {
          product_img: "Feed/Poultry/Layer/3. Layer Developer Crumbs.png",
          productName: "Layer Developer",
          desc: "The Layer Developer feed helps finalize the pullet's overall development in the mature and developed bird. This feed is offered to the chick from the age of 9th week and 17th week. However, when they reach the 17th week, your bird should be fully developed and ready to lay the eggs. However, it is pretty essential and crucial to fine-tune the weights of a bird once it reaches the level of maturity. We must understand that overweight and underweight birds are not appropriate for laying eggs.",
        },
        productId: 13,
      },

      {
        subCategory: "Layer Feed",
        Data: {
          product_img: "Feed/Poultry/Layer/4. Layer Pre- Lay Crumbs.png",
          productName: "Layer Pre Lay",
          desc: "The Layer Pre lay is the feed that helps in the development of metabolism for calcium in the bird. The feed also helps to stem and reduce daily feed intake, which is usually related to the early production or laying of eggs. Layer Pre-Lay feed, when used correctly, will even help to enhance the consistency of the pullet flock. The feed is usually given to the layer from the 18th week till the time when the production of egg reaches the weekly production.",
        },
        productId: 14,
      },

      {
        subCategory: "Layer Feed",
        Data: {
          product_img: "Feed/Poultry/Layer/5. Layer Phase - 1.png",
          productName: "Layer Phase 1",
          desc: "Layer Phase 1 is the feed that is economical, nutritional and has a balanced line for the early stage of laying eggs. The feed has a high ingredient quality standard, leading to consistent or constant performance of laying eggs. In Layer Phase 1, the level of nutrition gets perfectly matched with the bird's daily feed intake. The feed helps to maximize the production of hen through different stages for laying enhanced feed utilization.",
        },
        productId: 15,
      },

      {
        subCategory: "Layer Feed",
        Data: {
          product_img: "Feed/Poultry/Layer/6. Layer Phase - 2.png",
          productName: "Layer Phase 2",
          desc: "Layer Phase 2 is offered to the chicks from the age of 46 weeks when the productivity of the bird’s egg is reduced to 90%. In the feed, the protein level is modified to offer enhanced levels of efficiency in the production of eggs.",
        },
        productId: 17,
      },
      {
        subCategory: "Layer Feed",
        Data: {
          product_img: "Feed/Poultry/Layer/7. Layer Phase - 3.png",
          productName: "Layer Phase 3",
          desc: "Layer phase 3 feed has been started from week 65 till the culling of the chicks. It is advisable to give this feed when the productivity of the bird's egg is below 85% and the egg's weight is above 59 gm. Layer phase 3 feed helps to gain weight with high meat quality. It also helps reduce feed wastage due to good-quality pellets and crumbles. It is also famous for immunity improvement and higher livability.",
        },
        productId: 18,
      },
      {
        subCategory: "Layer Feed",
        Data: {
          product_img: "Feed/Poultry/Layer/8. Layer Concentrate Premium.png",
          productName: "Layer Concentrate Premium",
          desc: "The Layer concentrate Premium feed consists of high-quality protein, fiber, ash, and other essential and crucial minerals and vitamins. The range is extensively appreciated for its hygienic processes, high quality, and ease of natural digestion. The feed consists of various micro-ingredients along with protein. These components are available in perfectly mixed concentrate. Apart from this, it also helps optimize the layers' persistence and performance and helps them in practical production. The Premium concentrate layer feed is the perfect foundation for the layer. The feed also helps in enhancing the shelf life of the egg.",
        },
        productId: 19,
      },
    ],
  },
  {
    category: "Hatchery",
    desc: "Caters to both - our internal requirements and external clients through high quality",
    subcategories: ["Hatchery"],
    products: [
      {
        subCategory: "Hatchery",
        Data: {
          product_img: "Hatchery/1.jpg",
          productName: "Hatchery",
          desc: `
          Our operations bring in the best of both worlds; so while we incorporate a number of best practices and technologies, we have leveraged our manufacturing and fabrication prowess to indigenously manufacture the incubators and hatcher and setter trays as per our requirements. Our hatcheries are spread across various locations in India.
          `
        },
        productId: 20,
      },

    ],
  },
  {
    category: "Proteins",
    desc: "",
    subcategories: ['Soya'],
    products: [
      {
        subCategory: "Soya",
        Data: {
          product_img: "Protein/SoyaHiproDeOiledCake.jpg",
          productName: "Soya Hipro De Oiled Cake",
          desc: "Calf starter helps transit the calf from the period of milk feeding to the period of dry feeding. Calf starter is crucial to the development of healthy rumen, growth of the body, and weaning of the calf. The Calf starter should be nutritious and palatable. Urea is not a starter feed as it is tough for the calf to digest urea. The feed meets protein, energy, vitamins, and minerals requirements. This feed should be given to the calf around the 5th day in the shallow bucket. You should start with just a handful and increase the quantity gradually.",
        },
        productId: 29,
      },

      {
        subCategory: "Soya",
        Data: {
          product_img: "Protein/SoyaStandardDeOiledCake.jpg",
          productName: "Soya Standard De Oiled Cake",
          desc: "Calf starter helps transit the calf from the period of milk feeding to the period of dry feeding. Calf starter is crucial to the development of healthy rumen, growth of the body, and weaning of the calf. The Calf starter should be nutritious and palatable. Urea is not a starter feed as it is tough for the calf to digest urea. The feed meets protein, energy, vitamins, and minerals requirements. This feed should be given to the calf around the 5th day in the shallow bucket. You should start with just a handful and increase the quantity gradually.",
        },
        productId: 30,
      }
    ],
  },
  {
    category: "Equipments",
    desc: "Poultry business is largely unorganized in India and carried out through small scale operations. This makes quality equipment difficult to come by as they are mostly imported and hence costly. It is also difficult to get these equipment customized to suit individual needs and Indian conditions. We realized this need for good quality equipment and started Skylark Equipments Pvt. Ltd. in 1999. Through this business, we not only satisfy our internal need for specialized equipment but also provide a high-quality yet low-cost alternative to Indian poultry farmers. Some of the equipment that we design, engineer and fabricate, include:",
    subcategories: ['Hatchery Equipments', 'Farm Equipments', 'Cages', 'Ventilation'],
    products: [
      {
        subCategory: "Hatchery Equipments",
        Data: {
          product_img: "Equipments/1.png",
          productName: "Multistage Double Setter",
          desc: `<ul><li>ROBUST IN CONSTRUCTION.</li><li>RELIABLE &amp; ACCURATE TURNING MECHANISM.&nbsp;</li><li>UNIQUE SETTER TRAY DESIGN FOR EASY TRANSFER.</li><li>PROVEN DESIGN FOR STABLE ENVIRONMENT.&nbsp;</li><li>EASY TO OPERATE AND POWER EFFICIENT.&nbsp;</li><li>40MM THICK PUF PANEL BODY IN 0.5MM THICK PPGI COLOR COATED SHEET. &nbsp;</li><li>1 N2 NOS FAN WITH SS BLADE FITTED ON MACHINE FOR TROUBLE FREE OPERATION. &nbsp;</li></ul>`
        },
        productId: 20,
      },

      {
        subCategory: "Hatchery Equipments",
        Data: {
          product_img: "Equipments/2.png",
          productName: "Single Stage Setter",
          desc: `<ul><li>METICULOUSLY DESIGNED.</li><li>EASY TO HANDLE THE SETTER TROLLY WITH ROBUST CONSTRUCTION.&nbsp;</li><li>RELIABLE &amp; ACCURATE TURNING MECHANISM.</li><li>UNIQUE SETTER TRAY DESIGN FOR EASY TRANSFER.&nbsp;</li><li>EASY TO CONTROL THE UNIFORM TEMP AND HUMIDITY.&nbsp;</li><li>EASY TO OPERATE AND ENERGY EFFICIENT. &nbsp;</li><li>40MM THICK PUF PANEL BODY IN 0.5MM THICK PPGI COLOR COATED/SS SHEET. &nbsp;</li></ul>`
        },
        productId: 21,
      },
      {
        subCategory: "Hatchery Equipments",
        Data: {
          product_img: "Equipments/3.png",
          productName: "Hatcher Machine",
          desc: `<ul><li>STURDY BUILD&nbsp;</li><li>WELL DESIGNED HATCHER TRAY TO ENSURE MAXIMUM AIRFLOW &amp; HUMIDITY.&nbsp;</li><li>BEST HATCHER TROLLEY FOR EASY MANOEUVRE.&nbsp;</li><li>PROVEN DESIGN FOR CONSISTENT PRODUCTION MAXIMUM HATCHABILITY&nbsp;</li><li>EASY TO OPERATE  POWER EFFICIENT.&nbsp;</li><li>40MM THICK PUF PANEL BODY IN 0.5MM THICK PPGI COLOR COATED SHEET.&nbsp;</li><li>1 NOS FAN WITH SS BLADE FITTED ON MACHINE FOR TROUBLE FREE OPERATION.&nbsp;</li></ul>`
        },
        productId: 22,
      },
      {
        subCategory: "Hatchery Equipments",
        Data: {
          product_img: "Equipments/4.png",
          productName: "Setter Tray",
          desc: `<ul><li>DIMENSION :- 720 X 300 X 38 MM&nbsp;</li><li>APPROX WEIGHT :- 0.580 KG&nbsp;</li><li>EGG CAPACITY :- 90 EGG&nbsp;</li><li>COLOUR :- BLACK&nbsp;</li><li>STANDARD PKG :- 10 TRAY&nbsp;</li><li>APPROX PKG WEIGHT :- 6 KG&nbsp;</li><li>PKG DIMENSION :- 720 X 300 X 345 MM.&nbsp;</li><li>EASY TO SANITIZE.</li></ul>`
        },
        productId: 23,
      },
      {
        subCategory: "Hatchery Equipments",
        Data: {
          product_img: "Equipments/5.png",
          productName: "Setting  Trolley",
          desc: `<ul><li>DIMENSION:- 1480 X 775 MM&nbsp;</li><li>EGG CAPACITY :- 4860 EGG&nbsp;</li><li>COLOUR :- GREY&nbsp;</li><li>CAPACITY :- 54 TRAY</li><li>PKG DIMENSION:- 1482 MM X 780 MM</li></ul>`
        },
        productId: 24,
      },
      {
        subCategory: "Hatchery Equipments",
        Data: {
          product_img: "Equipments/6.png",
          productName: "Hatcher Trolley",
          desc: `<ul><li>DIMENSION:- 820 X 690 X 800 MM</li><li>WEIGHT :- 7.450 KG&nbsp;</li><li>EGG CAPACITY :- 2520 EGG&nbsp;</li><li>COLOUR :- GRAY&nbsp;</li><li>TRAY CAPACITY :- 14 TRAY</li></ul>`
        },
        productId: 24,
      },
      {
        subCategory: "Hatchery Equipments",
        Data: {
          product_img: "Equipments/7.png",
          productName: "Hatcher Tray",
          desc: `<ul><li>DIMENSION:- 790 x 680 x 130mm</li><li>APPROX WEIGHT :- 2.3 KG</li><li>EGG CAPACITY :- 180 EGG</li><li>COLOUR :- WHITE &nbsp;</li><li>STANDARD PKG:- 5 TRAY&nbsp;</li><li>APPROX PKG WEIGHT :-12 KG &nbsp;</li><li>PKG DIMENSION:- 790 X 680 X 625 MM&nbsp;</li><li>EASY TO CLEAN &amp; DRY&nbsp;</li></ul>`
        },
        productId: 25,
      },
      {
        subCategory: "Hatchery Equipments",
        Data: {
          product_img: "Equipments/8.png",
          productName: "Fumigation Chamber",
          desc: `<ul><li>Disinfect eggs in short time.&nbsp;</li><li>Sturdy design Safe for operator.&nbsp;</li><li>40mm thick puf panel body in 0.5mm thick ppgl color coated sheet.</li></ul>`
        },
        productId: 26,
      },
      {
        subCategory: "Hatchery Equipments",
        Data: {
          product_img: "Equipments/9.png",
          productName: "Egg trolley",
          desc: `<ul><li>BEST SUITABLE TO TRANSPORT EGGS WITH CARE FOR AN OPTIMUM HATCH.&nbsp;</li><li>ELIMINATE CRACKING OF EGG DURING TRANSPORTATION.&nbsp;</li><li>EASY TO SANITIZE.&nbsp;</li><li>EASY TO USE.&nbsp;</li><li>EASY TO MAINTAIN.&nbsp;</li><li>DIMENSION :- LXBXH 53.5” X 43” X 73” 7.</li><li>CAPACITY :- 5400 EGGS (180 Trays)</li></ul>`
        },
        productId: 27,
      },
      {
        subCategory: "Hatchery Equipments",
        Data: {
          product_img: "Equipments/10.png",
          productName: "Chicks Traveller",
          desc: `<ul><li>BEST SUITABLE TO TRANSPORT DAY OLD CHICKS IN ALL CLIMATE.&nbsp;</li><li>WORKING WITH EVAPORATIVE COOLING PRINCIPLE.</li><li>HANDLE ENVIRONMENTAL ISSUES OF DOC IN HEAVY TRAFFIC JAM ALSO.&nbsp;</li><li>OFFERING CHICKS TRAVELLER ACCORDING TO VEHICLE CHASSIS MODEL AND DESIRED CAPACITY</li></ul>`
        },
        productId: 28,
      },
      {
        subCategory: "Farm Equipments",
        Data: {
          product_img: "Equipments/11.png",
          productName: "Weld Mesh For Cage",
          desc: "<ul><li>Made off 275&nbsp;<strong>GSM GALVANIZED WIRE.</strong></li><li>Different models suitable for chicks stage, grower stage and laying stages.</li><li>Top mesh made of 3MM dia. wire.</li><li>Standard length of top mesh :- 50’ long roll.</li><li>Side partition made of 3MM dia. wire.</li><li>Standard length of side partition mesh mesh :- 50’ long roll.</li><li>Bottom mesh made of 2.5 mm dia. wire.</li><li>Standard length of bottom mesh :- Suitable length of 12 TO 16 number of cage box.</li><li>Gate :-4 MM dia. wire.</li><li>Depth of cage box :- 15”</li></ul>",
        },
        productId: 31,
      },

      {
        subCategory: "Farm Equipments",
        Data: {
          product_img: "Equipments/12.png",
          productName: "BATTERY CAGE FOR BREEDER FARM ",
          desc: "<ul><li>Well designed for breeder birds.</li><li>Large headroom for good ventilation as well as hygienic conditions.</li><li>Easy to manage the birds.</li><li>Made off 275 GSM Galvanized Wire.</li><li>Rigid Design.</li><li>Rearing and Laying cages available.</li><li>Removal of manure is very easy.</li><li>Easy to sanitize.</li></ul>",

        },
        productId: 32,
      },

      {
        subCategory: "Farm Equipments",
        Data: {
          product_img: "Equipments/13.png",
          productName: "PVC CHICK NET",
          desc: "<ul><li>Dimension:- 1 MTR x 25 MTR</li><li>Colour :-&nbsp; Yellow</li><li>Box Size :- 15 MM x 15 MM</li><li>Standard Pkg :- 1 PCS</li><li>Approx Pkg Weight :-8.5 Kg</li><li>Pkg Dimension :- 10.5 x 40 Inch&nbsp;</li></ul>"
        },
        productId: 33,
      },
      {
        subCategory: "Farm Equipments",
        Data: {
          product_img: "Equipments/14.png",
          productName: "PVC Mat",
          desc: "<ul><li>Dimension L x B:- 325MM x 335MM</li><li>Colour:- White</li><li>Box Size:- 22 MM x 55 MM (i/s)</li><li>Standard Pkg:- 160 nos</li><li>Packet Weight:- 24 Kg Approx.</li><li>Minimize Egg Crack.</li><li>Easy to sanitize.</li></ul>",
        },
        productId: 34,
      },
      {
        subCategory: "Farm Equipments",
        Data: {
          product_img: "Equipments/15.png",
          productName: "Rubber Mat",
          desc: "<ul><li>Standard Size :- 16.25” x 15” &amp; 15” x 15”</li><li>Color :- Black</li><li>Grade :- Soft</li><li>Standard Pkg :- 1 Pcs</li><li>Customized sizes can be supplied.</li></ul>",
        },
        productId: 35,
      },
      {
        subCategory: "Farm Equipments",
        Data: {
          product_img: "Equipments/16.png",
          productName: "Nipple- GI-01",
          desc: "<ul><li>Type :- GI-01 layer Nipples</li><li>Ideal for :- Egg-laying hens</li><li>Nipple Mechanism :- Vertical up/down action triggers water release</li><li>Lock Mechanism :- Available in red and yellow colours &amp; other colours on demand&nbsp;</li><li>Colours :- Double- lock mechanism</li><li>Water flow rate :- 12 ml per minute or the water flow rate to be customized as per customer requirements.</li></ul>",
        },
        productId: 36,
      },
      {
        subCategory: "Farm Equipments",
        Data: {
          product_img: "Equipments/17.png",
          productName: "Nipple- GI-04",
          desc: "<ul><li>GI-04 all directions nipples</li><li><strong>360° movement, a little nudge can help trigger water flow</strong></li><li>Available in red and yellow colours &amp; other colours on demand.</li><li>Double-lock mechanism</li><li>12 ml per minute or the water flow rate to be customized as per customer requirements.</li></ul>",
        },
        productId: 37,
      },
      {
        subCategory: "Farm Equipments",
        Data: {
          product_img: "Equipments/18.png",
          productName: "PP Belt",
          desc: "<ul><li>Dimension :- 100mm width and Length as per requirement</li><li>Colour :- White&nbsp;</li><li>Standard Pkg :- 160 nos</li><li>Packet Weight :- 24 Kg Approx.</li><li>Minimize Egg Crack</li><li>Easy to sanitize</li></ul>",
        },
        productId: 38,
      },
      {
        subCategory: "Farm Equipments",
        Data: {
          product_img: "Equipments/19.png",
          productName: "AI Gun",
          desc: "<ul><li>Standard packing :- 2 nos</li><li>Packet size :-30” x 14” x 18.5”&nbsp;</li><li>Packet weight :- 24 Kg approx.</li><li>Easy to maintain.</li><li>Easy to sanitize.</li></ul>",
        },
        productId: 39,
      },
      {
        subCategory: "Farm Equipments",
        Data: {
          product_img: "Equipments/20.png",
          productName: "Vaccinator",
          desc: "<ul><li>Standard packing :- 160 nos</li><li>Packet size :- 30” x 14” x 18.5”&nbsp;</li><li>Packet weight :- 24 Kg approx.</li><li>Easy to maintain.</li><li>Easy to sanitize.</li></ul>",
        },
        productId: 40,
      },
      {
        subCategory: "Farm Equipments",
        Data: {
          product_img: "Equipments/21.png",
          productName: "Evaporating Cooling Pad",
          desc: "<ul><li>Dimension :- 2 Feet wide length as desired.</li><li>Thickness :- 4 Inch , 2 Inch , 6 Inch</li><li>Made of good quality paper.</li></ul>",
        },
        productId: 41,
      },
      {
        subCategory: "Farm Equipments",
        Data: {
          product_img: "Equipments/22.png",
          productName: "Bulk Feed Tanker",
          desc: "<ul><li>Hygienic</li><li>Can transport multiple product.</li><li>Capacity and model according to size of vehicle chassis.</li><li>Easy to align with SILO inlet.</li><li>High quality power pack and hydraulic cylinders.</li><li>Trouble free link mechanism.</li></ul>",
        },
        productId: 42,
      },
      {
        subCategory: "Farm Equipments",
        Data: {
          product_img: "Equipments/23.png",
          productName: "Light Filter Inlet",
          desc: "<ul><li>Dimension :- 4 x 3 x 6 feet </li></ul>",
        },
        productId: 43,
      },
      {
        subCategory: "Farm Equipments",
        Data: {
          product_img: "Equipments/24.png",
          productName: "Loader Conveyor System",
          desc: "<ul><li>Dimension :- 5359 x 1219 x 1320 MM</li><li>Easy to Operate.</li><li>Minimum time consumed.</li><li>Fully automatic.</li></ul>",
        },
        productId: 44,
      },
      {
        subCategory: "Farm Equipments",
        Data: {
          product_img: "Equipments/25.png",
          productName: "Egg Collection System",
          desc: "<ul><li>Minimum man power consumption.</li><li>Fully automatic.</li><li>Produced high quality and clean eggs.</li><li>Decrease time and payroll cost.</li><li>Easy operating.</li><li>Low maintenance requirement.</li></ul>",
        },
        productId: 45,
      },
      {
        subCategory: "Farm Equipments",
        Data: {
          product_img: "Equipments/26.png",
          productName: "AI Trolley",
          desc: "<ul><li>Dimension :- 2075 x 605 x 655 MM</li><li>Easy to use.</li></ul>",
        },
        productId: 46,
      },
      {
        subCategory: "Cages",
        Data: {
          product_img: "Equipments/27.png",
          productName: "A TYPE CAGE",
          desc: "<ul><li>Dimension :- 5359 x 1219 x 1320 MM</li><li>Easy to Operate.</li><li>Minimum time consumed.</li><li>Space Saving</li></ul>",
        },
        productId: 1,
      },
      {
        subCategory: "Cages",
        Data: {
          product_img: "Equipments/28.png",
          productName: "California Cage",
          desc: "<ul><li>Minimum man power consumption.</li><li>Produced high quality and clean eggs.</li><li>Decrease time and payroll cost.</li><li>Easy operating.</li><li>Low maintenance requirement.</li></ul>",
        },
        productId: 2,
      },
      {
        subCategory: "Cages",
        Data: {
          product_img: "Equipments/29.png",
          productName: "Battery Cage",
          desc: "<ul><li>Dimension :- 2075 x 605 x 655 MM</li><li>Easy to use.</li></ul>",
        },
        productId: 3,
      },
      {
        subCategory: "Ventilation",
        Data: {
          product_img: "Equipments/30.png",
          productName: "SKYFAN (6 BLADE)",
          desc: "<ul><li>Dimension: 54* 54 Inch</li><li>Capacity: 16000 CFM</li><li>Blades: SS</li><li>Motor: 1.5 HP 3 Phase</li></ul>",
        },
        productId: 1,
      },
      {
        subCategory: "Ventilation",
        Data: {
          product_img: "Equipments/31.png",
          productName: "Box Fan (50 inch)",
          desc: "<ul><li>Dimension: 52* 52 Inch</li><li>Capacity: 26500 CFM</li><li>MOC: Body and Mesh made of GI and blade made of Polymer</li><li>Blades: 3&nbsp;</li><li>Motor: 1.5 HP 3 Phase</li></ul>",
        },
        productId: 2,
      },
      {
        subCategory: "Ventilation",
        Data: {
          product_img: "Equipments/32.png",
          productName: "Air Circulation Fan",
          desc: "<ul><li>Dimension: 51* 51 Inch</li><li>Capacity: 22000 CFM</li><li>MOC: Body and shutter made of GI sheet and blade made of Polymer</li><li>Blades: 3&nbsp;</li><li>Motor: 1.5 HP 3 Phase</li></ul>",
        },
        productId: 3,
      },
    ],
  },
  {
    category: "Food",
    desc: "",
    subcategories: [],
    products: [
      {
        Data: {
          product_img: "Food/Anta.png",
          productName: "Anta Food",
          url: 'http://antafoods.in/',
          desc: `
          Craving some delicious chicken nuggets? Well, ANTA Chicken Nuggets is exactly what you need. 
          Luscious small bites that take almost no time to cook. 
          These juicy nuggets are the perfect companion to your evening.`
        },
      },
      {
        Data: {
          product_img: "Food/Nutrich.png",
          productName: "Nutrich Food",
          url: 'https://www.skylarkfoods.com/product.html',
          desc: `We market fresh and frozen chicken in wholesale as well as retail segments in multiple weight packs under brand Nutrich . 
          Our clients include prestigious players such as Walmart, Reliance Retail Ltd., 
          Nirula's, and many Premium Hotels. Apart from these our franchisee outlets also market the Nutrich range.`
        },
      }
    ],
  }
];

export default productsData;
