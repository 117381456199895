import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import "../assests/styles/navbar.css";
import Logo from "../assests/images/Logo.png";
import whiteMenuBar from "../assests/images/menu.png";
import greenMenuBar from "../assests/images/menuGreen.png";
import navClose from "../assests/images/navClose.png";

const Navbar = (props) => {

  useEffect(() => {
    if (props.navTransparent) {
      window.addEventListener("scroll", handleScroll);
    }

    const menuBar = document.querySelector(".menubar");
    const navClose = document.querySelector(".navClose");
    menuBar.addEventListener("click", () => {
      document.querySelector(".mobileNav").classList.toggle("showNav");
    });

    navClose.addEventListener("click", () => {
      document.querySelector(".mobileNav").classList.remove("showNav");
    });

    return () => {
      if (props.navTransparent) {
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleScroll = () => {
    const menu = document.querySelector(".menubar img");
    if (props.navTransparent) {
      if (window.scrollY > window.innerHeight - 200) {
        document.querySelector(".navbar").classList.add("navbgwhite");
        menu.src = greenMenuBar;
      } else {
        document.querySelector(".navbar").classList.remove("navbgwhite");
        menu.src = whiteMenuBar;
      }
    }
  };

  return (
    <nav
      className={` navbar  ${props.navTransparent ? "navTransparent" : ""
        } navbar-fixed-top navbar-expand-lg`}
    >
      <div className="d-lg-none d-flex justify-content-between align-items-center w-100">
        <div className="menubar">
          <img
            src={props.navTransparent ? whiteMenuBar : greenMenuBar}
            alt=""
          />
        </div>
        <a className="Nav-logo text-center" href="/">
          <img alt="" src={Logo} />
        </a>
      </div>

      <div className=" mobileNav  d-lg-flex w-100 pt-5 py-lg-0 justify-content-between align-items-center row mx-0">
        <img src={navClose} alt="" className="navClose d-lg-none" />
        <div className="col-lg-4">
          <ul className="navbar-nav left-side d-flex justify-content-evenly">
            <li className="nav-item">
              <NavLink to="/" className="nav-link">
                HOME
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/about" className="nav-link">
                ABOUT
              </NavLink>
            </li>
            <li className="nav-item nav-left">
              <NavLink to="/products" className="nav-link">
                PRODUCTS
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="col-lg-auto text-center d-none d-lg-block">
          <NavLink to="/" className="nav-link Nav-logo text-center">
            <img className="" alt="site logo" src={Logo} />
          </NavLink>
        </div>
        <div className="col-lg-4">
          <ul className="navbar-nav right-side d-flex justify-content-evenly">
            <li className="nav-item nav-left">
              <NavLink to="/sustainability" className="nav-link">
                SUSTAINABILITY
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/career" className="nav-link">
                CAREERS
              </NavLink>
            </li>

            <li className="nav-item nav-left">
              <NavLink to="/contact" className="nav-link">
                CONTACT
              </NavLink>
            </li>

          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
