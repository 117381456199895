import React from "react";
import NavBar from "../components/Navbar";
import ContactContant from "../components/Home/contact";
import FooterPage from "../components/Footer";

const ContactPage = () => {
  return (
    <div>
      <NavBar color={"#75B8AD"} isimage={false} />
      <ContactContant />
      <FooterPage bg={"#E9F8F4"} />
    </div>
  );
}

export default ContactPage;
