import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assests/styles/product/mainslider.css";

const MainCarousel = () => {

  const [homebg, sethomebg] = React.useState("productBanner1.png");

  React.useEffect(() => {

    let bannerImgArray = [
      "productBanner1.png",
      "productBanner2.png"
    ];

    let imgNo = 0;
    sethomebg(bannerImgArray[imgNo]);

    const bgInterval = setInterval(() => {
      if (imgNo < bannerImgArray.length - 1) {
        imgNo++;
        sethomebg(bannerImgArray[imgNo]);
      } else {
        imgNo = 0;
        sethomebg(bannerImgArray[imgNo]);
      }
    }, 5000);

    return () => {
      clearInterval(bgInterval);
    };
  }, []);

  function testing() {
    document.querySelector("html").style.scrollBehavior = "smooth";
    setTimeout(() => {
      const position = document.querySelector(".productCart").offsetTop;
      window.scrollTo(0, position - 80);
    }, 300);
    setTimeout(() => {
      document.querySelector("html").style.scrollBehavior = "initial";
    }, 700);
  }

  return (
    <div className="MainSlider">
      <div
        className="home"
        style={{
          background: `url('/images/${homebg}')`,
        }}
      >
        <div className="text-white d-flex-center">
          <div className="container">
            <div className="row flex justify-content-between flex-column-reverse flex-md-row">
              <div
                className="col-md-7 col-lg-6 col-xl-5   px-3 "
                data-aos="fade"
                data-aos-delay="400"
              >
                <div className="">
                  <h5 className="text-white text-left" data-aos="fade-up">
                    Healthy and nutritious feed produced by our feed mills is currently the most preferred diet in the Indian poultry and livestock market. The different types of feed we produce are optimally tailored to the different requirements of livestock and poultry.
                  </h5>
                </div>

                <div className="mt-4 opacity-60" data-aos="fade-up">Our products include</div>
                <div className=" mt-4">
                  <div className="d-flex gap-4" data-aos="fade-up">
                    <h5 onClick={() => testing()}>Feed</h5>
                    <h5 onClick={() => testing()}>Hatchery</h5>
                    <h5 onClick={() => testing()}>Equipment</h5>
                  </div>
                  <div className="d-flex gap-4 mt-2" data-aos="fade-up">
                    <h5 onClick={() => testing()}>Protein</h5>
                    <h5 onClick={() => testing()}>Food</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainCarousel;
