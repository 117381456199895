import phone from "./../../assests/images/phone.png";
import map from "./../../assests/images/Maps.png";
import mail from "./../../assests/images/Mail.png";
import "../../assests/styles/Home/maps.css";
import "../../assests/styles/Home/contact.css";
import React from "react";
import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api";

const marginTop = {
  marginTop: "30em",
};

const SkylarkFeeds = { lat: 29.42637, lng: 76.668123 };
const SkylarkProteins = { lat: 23.5142805, lng: 75.8942383 };
const SkylarkHatcheries = { lat: 29.1299001, lng: 76.4032259 };

function ContactContant() {

  const [currentLoad, setCurrentLoad] = React.useState(SkylarkFeeds);

  const setLoad = (currentLoad) => {
    document.getElementById('SkylarkFeeds').checked = false;
    document.getElementById('SkylarkProteins').checked = false;
    document.getElementById('SkylarkHatcheries').checked = false;
    document.getElementById(currentLoad).checked = true;
  }

  const { isLoaded } = useJsApiLoader({ googleMapsApiKey: 'AIzaSyCgjudE9ERwz4xasQvYrrSE8yaqVGPkras' });

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App px-4 px-md-0" onLoad={() => { setLoad('SkylarkFeeds') }}>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      ></link>
      <div className="contact-header mt-3 mt-md-5" data-aos="fade">
        <h1>Contact Us</h1>
      </div>
      <div className="mt-3" data-aos="fade">
        <p className="contact-head-desc">We’d love to here from You</p>
      </div>

      <div class="wrapper">
        <div class="container2">
          <div class="tabs">
            <input type="radio" onClick={() => { setLoad('SkylarkFeeds'); setCurrentLoad(SkylarkFeeds) }} class="tabs__button" id="SkylarkFeeds" />
            <label class="tabs__text" for="SkylarkFeeds">Skylark Feeds</label>
            <input type="radio" onClick={() => { setLoad('SkylarkProteins'); setCurrentLoad(SkylarkProteins) }} class="tabs__button" id="SkylarkProteins" />
            <label class="tabs__text" for="SkylarkProteins">Skylark Proteins</label>
            <input type="radio" onClick={() => { setLoad('SkylarkHatcheries'); setCurrentLoad(SkylarkHatcheries) }} class="tabs__button" id="SkylarkHatcheries" />
            <label class="tabs__text" for="SkylarkHatcheries">Skylark Hatcheries</label>
          </div>
        </div>
      </div>

      <div className="Map-center row mt-2">
        <GoogleMap
          center={currentLoad}
          mapContainerStyle={{
            width: '70%',
            height: '50%',
            position: 'absolute',
            borderRadius: "5px",
            border: "1px solid grey",
            marginTop: "1em",
          }}
          options={{
            streetViewControl: false,
            mapTypeControl: false
          }}
          zoom={16}>
          <Marker position={currentLoad} />
        </GoogleMap>
      </div>


      <div className="container" style={marginTop}>
        <div data-aos="fade">
          <h3 className="contact-title3 mt-5 pt-4">OUR HEAD OFFICE</h3>
        </div>
        <div className="contact-row my-3 my-md5 pt-3 row" data-aos="fade">
          <div className="contact-column col-md-4">
            <div className="contact-card">
              <div>
                <img src={phone} alt="phone" />
              </div>
              <p className="contact-desc px-0">1800 180 8866</p>
            </div>
          </div>

          <div className="contact-column  col-md-4">
            <div className="contact-card">
              <div>
                <img src={map} alt="map" />
              </div>
              <p className="contact-desc">
                Village Khera Khemawati, Tehsil Safidon, Jind (Haryana) - 126112
              </p>
            </div>
          </div>

          <div className="contact-column  col-md-4">
            <div className="contact-card">
              <div>
                <img src={mail} alt="mail" />
              </div>
              <p className="contact-desc">info@skylarkhatcheries.com</p>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default ContactContant;
