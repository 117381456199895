import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import Facebook from "../assests/images/facebookLogo.png";
import LinkedIn from "../assests/images/linkedinLogo.png";
import Youtube from "../assests/images/youtubeLogo.png";
import Instagram from "../assests/images/InstagramLogo.png";
import "../assests/styles/footer.css";
import Logo from "../assests/images/Logo.png";

const FooterPage = ({ bg }) => {
	return (
		<div>
			<MDBFooter
				id="footer-main"
				className="font-small pt-4 mt-4"
				style={bg ? { backgroundColor: bg } : {}}
			>
				<MDBContainer fluid className="text-center text-md-left">
					<MDBRow className="space-between">
						<MDBCol md="4" lg="4">
							<img src={Logo} alt="footer_logo" className="footer-Image media-lefy" />
							<p id="footer-text" className="text-xl-left mt-2">
								Quality, at Skylark, is not just a word but a philosophy that is ingrained across our processes and operations. We have always strived to be a quality leader in India's poultry sector and it is this quest that helps us stand apart.
							</p>
						</MDBCol>
						<MDBCol id="footer-links" sm="6" md="4" lg="3">
							<h5 className="title">Contacts Us</h5>
							<li className="list-unstyled">
								Skylark Feeds Pvt. Ltd., Village Khera Khemawati,Tehsil Safidon, Jind (Haryana)-126112
							</li>
							<li className="list-unstyled">Email: <a href="mailto:info@skylarkfeeds.com">info@skylarkfeeds.com</a></li>
							<li className="list-unstyled">Toll Free: <a href="tel:1800 180 8866">1800 180 8866</a></li>
						</MDBCol>
						<MDBCol
							id="footer-links"
							style={{
								position: "relative",
								display: "flex",
								flexDirection: "column",
								justifyContent: "space-between",
								gap: "20px",
							}}
							lg="3"
						>
							<div className="footer-social-icons mt-3 mt-md-0">
								<a
									href="https://www.youtube.com/channel/UCXrXTw0VMhAt4W29v14-xwA/featured"
									target="_blank"
									rel="noreferrer"
								>
									<img src={Youtube} alt="Youtube" />
								</a>
								<a
									href="https://www.linkedin.com/company/skylarkfeeds/"
									target="_blank"
									rel="noreferrer"
								>
									<img src={LinkedIn} alt="LinkedIn" />
								</a>
								<a href="https://www.facebook.com/skylarkpoultry" target="_blank" rel="noreferrer">
									<img src={Facebook} alt="Facebook" />
								</a>
								<a href="https://www.instagram.com/group_skylark/" target="_blank" rel="noreferrer">
									<img src={Instagram} alt="Instagram" />
								</a>
							</div>
							<div className="footer-copyright text-center text-lg-end pr-text">
								Copyright &copy; {new Date().getFullYear()} Skylark Feeds Pvt. Ltd
							</div>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			</MDBFooter>
		</div>
	);
};

export default FooterPage;
