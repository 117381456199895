import React, { useState, useEffect } from "react";
import NavBar from "../components/Navbar";
import Home from "../components/Home/Home";
import Business from "../components/Home/Business";
import Parivar from "../components/Home/parivar";
import Sustainable from "../components/Home/sustainable";
import Footer from "../components/Footer";
import Director from "../components/Home/director";
import Global from "../components/Home/global";
import "../assests/styles/Home/home.css";

const HomePage = () => {

  const [homebg, sethomebg] = useState("HomePage1.jpg");
  // const [currentSection, setCurrentSection] = useState(1);

  useEffect(() => {

    let bannerImgArray = [
      "HomePage1.jpg",
      "HomePage2.jpg",
      "HomePage3.jpg",
    ];

    let imgNo = 0;
    sethomebg(bannerImgArray[imgNo]);

    const bgInterval = setInterval(() => {
      if (imgNo < bannerImgArray.length - 1) {
        imgNo++;
        sethomebg(bannerImgArray[imgNo]);
      } else {
        imgNo = 0;
        sethomebg(bannerImgArray[imgNo]);
      }
    }, 5000);

    return () => {
      clearInterval(bgInterval);
    };

  }, []);

  // const scrollDown = () => {
  //   if (currentSection < 7) {
  //     setCurrentSection(prevCount => prevCount + 1);
  //   }
  // }

  // const scrollUp = () => {
  //   if (currentSection > 1) {
  //     setCurrentSection(prevCount => prevCount - 1);
  //   }
  // }


  // React.useEffect(() => {
  //   document.querySelector("html").style.scrollBehavior = "smooth";
  //   const position = document.querySelector(`.section${currentSection}`).offsetTop;
  //   window.scrollTo(0, position - 100);
  //   document.querySelector("html").style.scrollBehavior = "initial";
  // }, [currentSection]);

  return (
    <div >
      <NavBar color={"white"} isimage={false} navTransparent={true} />
      <div
        className="home"
        style={{
          background: `url('/images/home/${homebg}')`,
        }}
      >
        <Home />
      </div>

      <Business />

      <Parivar />

      <Global />

      <Director />

      <Sustainable />

      <Footer />
    </div>
  );
};

export default HomePage;
