import React, { Component } from "react";
import "../../assests/styles/About/team.css";
import Ceo3 from "../../assests/images/ceo33.png";
import Ceo2 from "../../assests/images/ceo22.png";
import Ceo1 from "../../assests/images/ceo11.png";

class Team extends Component {
	render() {
		return (
			<div className="team mt-5 pt-5 ">
				<div className="section-head" data-aos="fade-up">
					<h1>Our leadership team</h1>
					<h4>We have our offices everywhere</h4>
				</div>

				<div className="team-members mt-5 pt-2">
					<div className="member">
						<div className="card">
							<div className="image">
								<img src={Ceo1} alt="Ceo3"></img>
							</div>
							<div className="member-name">
								<h1 id="name">Jagbir Singh Dhull</h1>
							</div>
							<div className="member-position">
								<p id="position">
									Managing Director, <span className="keyword">Skylark Group</span>
								</p>
							</div>
							<div className="member-quote">
								<p id="quote">
									“If there is one word that summarizes Skylark, it is Passion - Passion for Quality, Passion for Excellence, and Passion for Progress.”
								</p>
							</div>
						</div>
					</div>

					<div className="member">
						<div className="card">
							<div className="image">
								<img src={Ceo2} alt="Ceo1" className="ceo2"></img>
							</div>
							<div className="member-name">
								<h1 id="name">Vikas Dhull</h1>
							</div>
							<div className="member-position">
								<p id="position">
									Director, <span className="keyword">Skylark Feeds</span>
								</p>
							</div>
							<div className="member-quote">
								<p id="quote">
									“Quality, at Skylark, is not just a word but a philosophy that is
									ingrained across our processes and operations”
								</p>
							</div>
						</div>
					</div>

					<div className="member">
						<div className="card">
							<div className="image">
								<img src={Ceo3} alt="Ceo2"></img>
							</div>
							<div className="member-name">
								<h1 id="name">Nitesh Dhull</h1>
							</div>
							<div className="member-position">
								<p id="position">
									Director, HR, <span className="keyword">Skylark Group</span>
								</p>
							</div>
							<div className="member-quote">
								<p id="quote">
									“Manpower is indeed the True Power behind the growth & success of Skylark”
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default Team;
