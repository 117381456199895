import React from "react";
import NextArrow from "../../assests/images/nextgreen.png";
import EnquiryFormImg from "../../assests/images/productForm.png";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import emailjs from "emailjs-com";

const Enquiryform = () => {
  // const [name, setname] = useState("");
  // const [email, setemail] = useState("");
  // const [productsInterested, setproductsInterested] = useState("");
  // const [comment, setcomment] = useState("");

  // const handleChange = (event) => {
  //   console.log("event", event);
  //   console.log(event.target.name);
  //   switch (event.target.name) {
  //     case "name":
  //       setname(event.target.value);
  //       break;
  //     case "email":
  //       setemail(event.target.value);
  //       break;
  //     case "interestedProducts":
  //       setproductsInterested(event.target.value);
  //       break;
  //     case "comment":
  //       setcomment(event.target.value);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  // const handleSubmit = () => {
  //   console.log(checkEmail(email));

  //   var templateParams = {
  //     sender_name: name,
  //     sender_email: email,
  //     sender_interestedProducts: productsInterested,
  //     sender_comment: comment,
  //   };

  //   emailjs
  //     .send(
  //       "",
  //       "",
  //       templateParams,
  //       ""
  //     )
  //     .then(
  //       function (response) {
  //         setname("");
  //         setemail("");
  //         setcomment("");
  //         setproductsInterested("");
  //       },
  //       function (error) {
  //         console.log("FAILED...", error);
  //         toast.error("Sorry, your form is not submitted !", {});
  //       }
  //     );

  //   if (checkEmail(email)) {
  //     toast.success("Form submitted Successfully !", {});
  //   } else {
  //     toast.error("Enter a valid Email !", {});
  //   }
  // };

  // function checkEmail(emailAddress) {
  //   let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

  //   var reValidEmail = new RegExp(regex);

  //   return reValidEmail.test(emailAddress);
  // }

  return (
    <div className=" py-5 enquiryForm" style={{ background: "#FAFAFA" }}>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer /> */}
      <div className="container">
        <div className="section-head" data-aos="fade-up">
          <h1>Need to know more</h1>
          <h4>
            Drop us an enquiry on any of the products you would like to try
          </h4>
        </div>
        <div className="row mt-5 justify-content-evenly align-items-center">
          <div className="col-md-5" data-aos="fade-up">
            <img
              src={EnquiryFormImg}
              alt=""
              className="d-none d-md-block img-fluid"
            />
          </div>
          <div className="col-md-5" data-aos="fade-up" data-aos-delay="400">
            <form
              className="row"
              onSubmit={(e) => {
                e.preventDefault();
                // handleSubmit();
              }}
            >
              <div className="col-md-6">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="john"
                  // value={name}
                  // onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputEmail4" className="form-label">
                  Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="inputEmail4"
                  name="email"
                  placeholder="john@workemail.com"
                  // value={email}
                  // onChange={handleChange}
                />
              </div>

              <div className="col-12 mt-4">
                <label htmlFor="interestedProducts" className="form-label">
                  Products interested in
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="interestedProducts"
                  name="interestedProducts"
                  placeholder=""
                  // value={productsInterested}
                  // onChange={handleChange}
                />
              </div>
              <div className="col-12 mt-4">
                <label htmlFor="comment" className="form-label">
                  Comment
                </label>
                <textarea
                  name="comment"
                  id="comment"
                  cols="30"
                  rows="5"
                  className="w-100  form-control"
                  // value={comment}
                  // onChange={handleChange}
                ></textarea>
              </div>
              {/* <input type="submit" value="submit" /> */}
              <div className="col-12 mt-4">
                <button type="submit" className="btn w-100 px-0 ">
                  <div className="d-flex align-items-center">
                    <span
                      style={{ flex: 1 }}
                      className="pr-color-bg text-white px-3"
                    >
                      Send Enquiry
                    </span>
                    <span className="bg-white px-3">
                      <img src={NextArrow} alt="" />
                    </span>
                  </div>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enquiryform;
