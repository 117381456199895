import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "../../assests/styles/Home/sustainable.css";

const Sustainable = () => {
	const history = useHistory();

	return (
		<div className="sustainable mt-5">
			<div className="sustain-heading" >
				<h1 >
					{" "}
					We are <br />
					<strong className="pr-color">Sustainable!</strong>
				</h1>
				<p>
					We provide sustenance with quality products enriched with nutritional
					values that you will not find anywhere else. This is what Skylark has been
					striving for since its inception. We believe in excellence with sustenance.
				</p>
				<Button
					className="sustain-button"
					variant="success"
					onClick={() => history.push("/sustainability")}
				>
					Explore more <b>&#8594;</b>
				</Button>
			</div>
			<div className="sustain-right">
				<div className="biogas" onClick={() => history.push("/sustainability")}>
					<div className="sustain-right-head">
						<b>
							<h3>Biogas Plant</h3>
						</b>
						<p>
							In order to manage the large amount of organic waste generated in our
							facilities, we have installed indigenously designed biogas plants. The
							clean energy generated from these plants enables us to depend less on the
							grid electricity and diesel-based generators.
						</p>
					</div>
				</div>
				<div className="fertilizer" onClick={() => history.push("/sustainability")}>
					<div className="sustain-right-head">
						<h3>Fertilizers</h3>
						<p>
							Our diverse portflio of operations ranging from Grandparents farms to
							large-scale mechanised farming makes us one of the largest vertically
							integrated polutry produces in the worls
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Sustainable;
