import React from "react";
import "../../assests/styles/About/network.css";
import chicken from "../../assests/images/Chicken.png";
import gitbranch from "../../assests/images/GitBranch.png";
import feed from "../../assests/images/Feed.png";
import eggs from "../../assests/images/Eggs.png";
import charging from "../../assests/images/charging-outline.png";
import chartbar from "../../assests/images/ChartBar.png";
import chartpie from "../../assests/images/ChartPieSlice.png";
import corporate from "../../assests/map/corporate.png";
import salesMarkting from "../../assests/map/salesMarkting.png";
import feedPlant from "../../assests/map/feedPlant.png";
import bioGasUnit from "../../assests/map/bioGasUnit.png";
import equipments from "../../assests/map/equipments.png";
import hatchery from "../../assests/map/hatchery.png";
import mineralMixturePlant from "../../assests/map/mineralMixturePlant.png";
import processing from "../../assests/map/processing.png";
import soyaSolvexPlant from "../../assests/map/soyaSolvexPlant.png";
import breedingFarm from "../../assests/map/breedingFarm.png";

class Network extends React.Component {

	state = { image: corporate };

	setImage(image) {
		this.setState({ image: image });
	}

	render() {
		return (
			<div className="row company-network">
				<div className="col-md-12">
					<div className="section-head" data-aos="fade-up">
						<h1>We are spread everywhere</h1>
					</div>
					<div className="description" data-aos="fade-up">
						<p id="network-descp">
							An extensive network of farms, processing facilities, trade partners,
							distributors and contract farmers across India
						</p>
					</div>
				</div>
				<div className="col-md-6">
					<div className="centered mt-5">
						<section className="cards1" data-aos="fade-up">
							<div className="card"
								onMouseEnter={() => this.setImage(corporate)}>
								<img alt="" className="bank-img" src={"/icons/bank.png"}></img>
								<h6>5 Corporate</h6>
								<h6> offices</h6>
							</div>

							<div className="card"
								onMouseEnter={() => this.setImage(salesMarkting)}>
								<img alt="" className="gitbranch-img" src={gitbranch}></img>
								<h6>24 Sales</h6>
								<h6>& Markting</h6>
							</div>

							<div className="card"
								onMouseEnter={() => this.setImage(bioGasUnit)}>
								<img alt="" className="chicken-img" src={charging}></img>
								<h6>2 Bio</h6>
								<h6>Gas Unit</h6>
							</div>

							<div className="card"
								onMouseEnter={() => this.setImage(feedPlant)}>
								<img alt="" className="feed-img" src={feed}></img>
								<h6>7 Feed</h6>
								<h6>Plants</h6>
							</div>

						</section>
						<section className="cards2" data-aos="fade-up">

							<div className="card"
								onMouseEnter={() => this.setImage(breedingFarm)}>
								<img alt="" className="chicken-img" src={chicken}></img>
								<h6>5 Breeding</h6>
								<h6> Centers</h6>
							</div>

							<div className="card"
								onMouseEnter={() => this.setImage(equipments)}>
								<img alt="" className="bank-img" src={eggs}></img>
								<h6>2</h6>
								<h6>Equipments</h6>
							</div>

							<div className="card"
								onMouseEnter={() => this.setImage(hatchery)}>
								<img alt="" className="gitbranch-img" src={chartpie}></img>
								<h6>8</h6>
								<h6>Hatchery</h6>
							</div>

							<div className="card"
								onMouseEnter={() => this.setImage(soyaSolvexPlant)}>
								<img alt="" className="chicken-img" src={charging}></img>
								<h6>1 Soya </h6>
								<h6>Solvex Plant</h6>
							</div>

						</section>
						<section className="cards2" data-aos="fade-up">

							<div className="card"
								onMouseEnter={() => this.setImage(mineralMixturePlant)}>
								<img alt="" className="feed-img" src={chartbar}></img>
								<h6>Mineral</h6>
								<h6>Mixture Plant</h6>
							</div>

							<div className="card"
								onMouseEnter={() => this.setImage(processing)}>
								<img alt="" className="chicken-img" src={charging}></img>
								<h6>1 Processing </h6>
								<h6> (Nutrich)</h6>
							</div>

						</section>
					</div>
				</div>
				<div className="col-md-6">
					<div className="imag" data-aos="fade-up">
						<img alt="" id="india-img" src={this.state.image} />
					</div>
				</div>
			</div>
		);
	}
}
export default Network;
