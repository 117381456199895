import React from "react";
import FooterPage from "../Footer";
import Navbar from "../Navbar";
import "../../assests/styles/Sustainibility/sustainable.css";
import manureImg from "../../assests/Sustainability/Manure.jpg";
import BiogasImg from "../../assests/Sustainability/Biogas.jpg";
import solarSystemImg from "../../assests/Sustainability/SolarSystem.jpg";

const Sustainibility = () => {

  const scrollTo = (id) => {
    document.querySelector("html").style.scrollBehavior = "smooth";
    const position = document.getElementById(id).offsetTop;
    window.scrollTo(0, position - 100);
  }

  return (
    <>
      <Navbar isimage={false} navTransparent={true} />
      <div className="header bg-gray pt-3 pt-md-0 bg-image" >
        <div className="container pt-3 pt-md-0" >
          <div className="row flex justify-content-between flex-column-reverse flex-md-row align-items-center">
            <div
              className="col-md-7 col-lg-6 col-xl-5 py-5 px-3 px-xl-5 mar-top-5"
              data-aos="fade-right"
              data-aos-delay="400"
            >
              <h1>Small Steps, Big Changes</h1>
              <p className="mt-4 white">
                At Skylark, we have always strived to be a responsible corporate
                citizen in every geography that we operate in. Keeping in mind
                our nature of business, we seek to make a positive difference in
                following avenues:
              </p>
              <div className="mt-4 ">
                <h5 className="font-weight-bold" onClick={() => { scrollTo('bia-gas') }}>Biogas Plant </h5>
                <h5 className="font-weight-bold mt-3" onClick={() => { scrollTo('solar-energy') }}>
                  Solar Energy Solutions
                </h5>
                <h5 className="font-weight-bold mt-3" onClick={() => { scrollTo('free-manure') }}>
                  Organic Manure
                </h5>
              </div>
            </div>
            <div
              className="col-md-5 text-center text-md-start  relative  px-xl-5 d-none d-md-block"
              data-aos="fade"
            >
            </div>
          </div>
        </div>
      </div>

      <div className=" sustainable-items  mt-5  pr-text " >
        <div className="container">
          <div className="row flex flex-column-reverse flex-md-row justify-content-evenly py-2 py-md-5" >
            <div
              className="col-md-6 col-lg-5 px-4 px-md-3"
              data-aos="fade-up"
              data-aos-delay="500"
              id="bia-gas"
            >
              <h2 className="text-black">Biogas Plant</h2>
              <p className="mt-2">
                We have done our part by taking all necessary steps towards the conservation of the environment by managing the waste through self-developed biogas generators, further recycling the slurry by separating the solid waste from water, making it a zero waste biogas plant.We have installed our biogas plant on 11 different sites, with a total of 42 digestors  and the capacity of 24000 m3. The greenhouse gases produced in these plants are transformed into clean energy capable of generating 10,800 mW of energy annually.
              </p>
              <p className="mt-2">
                We have repurposed thousands of tons of waste on our facilities making them a zero pollution emission zone.
              </p>

            </div>
            <div className="col-md-6 col-lg-5 px-4 px-md-3 image-card" data-aos="fade-up">
              <div className="sustain-image mt-2 mb-3 my-md-0">
                <img src={BiogasImg} alt="" className="sustainable-image" />
              </div>
            </div>
          </div>
          <div className="row flex flex-column-reverse flex-md-row-reverse justify-content-evenly py-2 py-md-5" id="solar-energy">
            <div
              className="col-md-6 col-lg-5 px-4 px-md-3"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <h2 className="text-black">Solar Energy Solutions</h2>
              <p className="mt-3 mt-lg-2">
                Our efforts towards sustainability have encouraged us to take further steps towards using clean energy and saving our natural resources. We have installed Solar panels in our facilities which produce 5000mW solar energy annually that powers a major portion of our plants. Furthermore, we have developed big fans which save 30-50% energy and generates same power as produced at maximum energy.
              </p>
            </div>
            <div className="col-md-6 col-lg-5 px-4 px-md-3 image-card" data-aos="fade-up">
              <div className="sustain-image mt-2 mb-3 my-md-0">
                <img
                  src={solarSystemImg}
                  alt=""
                  className="sustainable-image"
                />
              </div>
            </div>
          </div>
          <div className="row flex flex-column-reverse flex-md-row justify-content-evenly py-2 py-md-5" id="free-manure">
            <div
              className="col-md-6 col-lg-5 px-4 px-md-3"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <h2 className="text-black">Organic Manure</h2>
              <p className="mt-3 mt-lg-2">
                In the attempt of reducing the amount of synthetic NPK in the soil for improved quality of food produced nationally, we provide our organic manure to the farmers at free of cost. We hope to encourage as many farmers as possible towards organic farming. As a corporate entity, we stand responsible to contribute not only to the quality of our products but also to the quality of life.
              </p>
              <p className="mt-2">
                not only to the quality of our products but also to the quality of life.
                We hope to see more and more farmers taking a step towards organic farming.

              </p>
            </div>
            <div className="col-md-6 col-lg-5 px-4 px-md-3 image-card" data-aos="fade-up">
              <div className="sustain-image mt-2 mb-3 my-md-0">
                <img src={manureImg} alt="" className="sustainable-image" />
              </div>
            </div>
          </div>
          <div
            className="pr-head-color text-center px-3 sus-future-heading"
            data-aos="fade-up"
          >
            <h2 className="py-lg-4">We are proud to say that we have saved a total of 10,112 tonne/year of carbon footprint and aim to increase this number in the coming years.</h2>
          </div>
        </div>
      </div>

      <FooterPage />
    </>
  );
};

export default Sustainibility;
