import Productcart from "../components/product/product_cart";
import MainCarousel from "../components/product/MainCarousel";
import FooterPage from "../components/Footer";
import NavBar from "../components/Navbar";
import Enquiryform from "../components/product/Enquiryform";

const productPage = () => {

  return (
    <div>
      <NavBar color={"white"} isimage={false} navTransparent={true} />
      <MainCarousel />
      <Productcart />
      <Enquiryform />
      <FooterPage />
    </div>
  );
}

export default productPage;
