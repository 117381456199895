import React, { useState, useEffect } from "react";
import FooterPage from "../Footer";
import Navbar from "../Navbar";
import "../../assests/styles/product/product-details.css";
import NextGreen from "../../assests/images/nextgreen.png";
import backDarkGreen from "../../assests/images/backDarkGreen.png";
import { Link, useParams } from "react-router-dom";
import productsData from "./productsData";

const Productdetails = () => {
  let { productId } = useParams();
  let { category } = useParams();
  const [productData, setproductData] = useState("");
  const [multiProductData, setMiltiproductData] = useState("");

  useEffect(() => {
    let data = productsData.filter((el) => {
      return el.category === category;
    });
    setMiltiproductData(data[0]);
    let SingleProduct;
    data[0].products.forEach((product) => {
      if (product.Data.productName === productId) {
        SingleProduct = product;
      }
    });
    setproductData(SingleProduct);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Navbar />
      {productData ? (
        <div>
          <div className="header bg-primary-gradient">
            <div className="container">
              <div className="row flex justify-content-between align-items-center flex-column-reverse flex-md-row">
                <div className="col-md-7 col-lg-6 col-xl-5 pt-5 left-banner-section px-4 px-xl-5">
                  <p className="allproductsBtn  d-flex align-items-center gap-2">
                    <Link to="/products" className="pr-head-color">
                      <img
                        src={backDarkGreen}
                        alt=""
                        style={{ width: "18px" }}
                      />{" "}
                      All Products
                    </Link>
                  </p>
                  <h1>{productData.Data.productName}</h1>
                  <div dangerouslySetInnerHTML={{ __html: productData.Data.desc }}></div>
                  <div className="mt-4">
                    <Link
                      to="/contact"
                      className="enquireBtn pr-color d-flex align-items-center p-0 border-0"
                    >
                      <span className=" px-5 py-2">Enquire now</span>
                      <span className="bg-white px-2 py-2 text-center">
                        <img src={NextGreen} alt="" style={{ width: "24px" }} />
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="col-md-5 text-center text-md-start d-flex-c relative  px-xl-5">
                  <img
                    className="product-detail-img img-fluid"
                    src={`/images/products/${productData.Data.product_img}`}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-5 pb-5">
            <h3 className="text-center">You might also like</h3>
            <div className="row product-items px-lg-0">
              {multiProductData.products &&
                multiProductData.products.map((product) => {
                  if (product.subCategory === productData.subCategory) {
                    return (
                      <div className="col-md-4 col-sm-5 mt-5 mt-md-4">
                        <div className="item">
                          <div className="item-img" onClick={() => { setproductData(product); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>
                            <img
                              src={`/images/products/${product.Data.product_img}`}
                              alt=""
                            />
                          </div>
                          <div className="mt-3 text-center">
                            <h4 className="greyheading">{product.Data.productName}</h4>
                            <Link
                              to="/contact"
                              className="enquireBtn pr-color d-flex align-items-center p-0 border-0 "
                            >
                              <span className=" px-5 py-2">Enquire now</span>
                              <span className="bg-white px-2 py-2 text-center">
                                <img src={NextGreen} alt="" style={{ width: "24px" }} />
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    )
                  } else {
                    return null;
                  }

                })
              }
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center m-5">
          <h1>Product Not Found</h1>
        </div>
      )}
      <FooterPage />
    </>
  );
};

export default Productdetails;
