export const Timelines = [
	{
		id: 1,
		year: 1985,
		icon: "cock",
		description: [
			"Me. Jagbir Singh Dhull and Mr. Jasbir Singh Deswal established Skylark Hatcheries",
		],
	},
	{
		id: 2,
		year: 1996,
		icon: "bank",
		description: [
			"Skylark research and breeding farm established at village Urlana Kalan, Haryana",
		],
	},
	{
		id: 3,
		year: 1997,
		icon: "location",
		description: [
			"Himgiri Hatcheries was set up in a village in Himachal Pradesh",
		],
	},
	{
		id: 4,
		year: 1999,
		icon: "bank",
		description: ["Skylark Equipments Division established"],
	},
	{
		id: 5,
		year: 2000,
		icon: "cock",
		description: [
			"Breeder capacity increased to 2,00,000. Skylark breeding farm renamed",
		],
	},
	{
		id: 6,
		year: 2001,
		icon: "bank",
		description: ["Operations expanded to Anand, Gujarat"],
	},
	{
		id: 7,
		year: 2002,
		icon: "location",
		description: ["Skylark Foods Pvt. Ltd. incorporated"],
	},
	{
		id: 8,
		year: 2003,
		icon: "bank",
		description: [
			"Skylark Feeds Pvt. Ltd. started production at the first-of-its-kind auto-batching feed plant in Safidon, Haryana",
		],
	},
	{
		id: 9,
		year: 2005,
		icon: "cock",
		description: [
			"Skylark Foods Pvt. Ltd. established a poultry processing plant at Sonipat, Haryana to process and package chicken hygienically",
		],
	},
	{
		id: 10,
		year: 2007,
		icon: "bank",
		description: ["Initiated large-scale mechanised farming"],
	},
	{
		id: 11,
		year: 2008,
		icon: "location",
		description: [
			"Introduced technologically advanced commercial broiler farms and Environment Control Houses with automatic drinking and silo-based feeding systems",
		],
	},
	{
		id: 12,
		year: 2009,
		icon: "bank",
		description: [
			"Pioneered the technology for producing antibiotic-free chicken",
		],
	},
	{
		id: 13,
		year: 2010,
		icon: "cock",
		description: [
			"Initiated Grandparent Operations for broiler breeders; Started manufacturing battery cages",
		],
	},
	{
		id: 14,
		year: 2012,
		icon: "bank",
		description: ["Established feed production plant at Pilani, Rajasthan"],
	},
	{
		id: 15,
		year: 2013,
		icon: "location",
		description: [
			"Initiated agricultural operations in West Africa through acquisitions",
		],
	},
	{
		id: 16,
		year: 2014,
		icon: "bank",
		description: [
			"Set up Feed Plant in Nepal",
			"Started operations at Raipur, Chattisgarh",
			"Commissioned Feed Plant at Purnia, Bihar",
			"Started Hatchery in Allahabad, Uttar Pradesh",
		],
	},
	{
		id: 17,
		year: 2015,
		icon: "cock",
		description: [
			"Set Up Parent Farm, Hatchery, Feed Mill, Commercial Layer Farm in Ghana, Africa",
			"Started Layer Operations in South India",
		],
	},
	{
		id: 18,
		year: 2019,
		icon: "bank",
		description: [
			"50% Share in Garinno Global Pvt Ltd.",
			"Skylark Sunline Company Ltd., Myanmar Established",
			"Skylark Protein Division Established",
		],
	},
	{
		id: 19,
		year: 2020,
		icon: "location",
		description: [
			"Entered cattle and fish feed market",
			"2020 Launched our RTC, RTE product line",
		],
	},
];
