import React from "react";
import users from "../../assests/images/Users.png";
import "../../assests/styles/career/careerForm.css";

const CurrentHoldings = ({ openPopUp }) => {
  const [selectedType, setSelected] = React.useState("All");

  const ApplyNow = (Position) => {
    localStorage.setItem('ApplyNow',Position);
    openPopUp();
  }

  return (
    <div className="mt-4 pt-1">
      <h2>Current Holdings</h2>
      <div className="row mt-5 pr-text" data-aos="fade-up">
        <div className="col-md-4 col-lg-3">
          <ul
            className="list-unstyled"
            style={{ fontWeight: 600, position: "fixed", top: 0 }}
          >
            {selectedType === 'All' ?
              <li className="py-2">
                <a href="javascript:void(0)" className="pr-text hover-pr-green active" onClick={() => { setSelected('All') }}>
                  {" "}
                  All
                </a>
              </li>
              :
              <li className="py-2">
                <a href="javascript:void(0)" className="pr-text hover-pr-green" onClick={() => { setSelected('All') }}>
                  {" "}
                  All
                </a>
              </li>
            }
            {selectedType === 'Production' ?
              <li className="py-2">
                <a href="javascript:void(0)" className="pr-text hover-pr-green active" onClick={() => { setSelected('Production') }}>
                  {" "}
                  Production
                </a>
              </li>
              :
              <li className="py-2">
                <a href="javascript:void(0)" className="pr-text hover-pr-green" onClick={() => { setSelected('Production') }}>
                  {" "}
                  Production
                </a>
              </li>
            }
            {selectedType === 'Sales & Marketing' ?
              <li className="py-2">
                <a href="javascript:void(0)" className="pr-text hover-pr-green active" onClick={() => { setSelected('Sales & Marketing') }}>
                  {" "}
                  Sales & Marketing
                </a>
              </li>
              :
              <li className="py-2">
                <a href="javascript:void(0)" className="pr-text hover-pr-green" onClick={() => { setSelected('Sales & Marketing') }}>
                  {" "}
                  Sales & Marketing
                </a>
              </li>
            }
          </ul>
        </div>
        <div className="col-md-8 col-lg-9">
          {selectedType === 'All' || selectedType === 'Production' ?
            (
              <>
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <img
                        src={users}
                        alt=""
                        style={{ width: "45px", height: "45px" }} />
                      <h3 className="text-black mb-0 ml-2">Hatchery Supervisor</h3>
                    </div>
                    <p>
                      <strong>Pan India</strong>
                    </p>
                  </div>
                  <div className="mt-2 pb-2">
                    <p> <strong>Company: </strong> Skylark Hatcheries Pvt. Ltd.</p>
                    <p> <strong>Experience: </strong> 3+ Years </p>
                    <p> <strong>Job Role: </strong>
                      Incubator and hatcher operation, Hatchery Supervision, Ventilation managemen
                    </p>
                    <span
                      className="pr-color font-weight-bold "
                      style={{ cursor: "pointer" }}
                      onClick={() => ApplyNow('Hatchery Supervisor')}
                    >
                      Apply now
                    </span>
                  </div>
                </div><hr className="my-4" />
              </>) : null
          }
          {selectedType === 'All' || selectedType === 'Sales & Marketing' ?
            (
              <>
                <div className="pt-2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <img
                        src={users}
                        alt=""
                        style={{ width: "45px", height: "45px" }}
                      />
                      <h3 className="text-black mb-0 ml-2">Farm Manager</h3>
                    </div>
                    <p>
                      <strong>Pan India</strong>
                    </p>
                  </div>
                  <div className="mt-2 ">
                    <p> <strong>Company : </strong> Skylark Hatcheries Pvt. Ltd.</p>
                    <p> <strong>Experience : </strong> 3+ Years </p>
                    <p> <strong>Skills : </strong>
                      Knowledge of AI, Birds and Egg Handling, Vaccination and Farm Management
                    </p>
                    <span
                      className="pr-color font-weight-bold "
                      style={{ cursor: "pointer" }}
                      onClick={() => ApplyNow('Farm Manager')}
                    >
                      Apply now
                    </span>
                  </div>
                </div>
                <hr className="my-4" />

                <div className="pt-2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <img
                        src={users}
                        alt=""
                        style={{ width: "45px", height: "45px" }}
                      />
                      <h3 className="text-black mb-0 ml-2">Manager/ ASM/ Executive Sales - Broiler Feed Sales</h3>
                    </div>
                    <p>
                      <strong>Pan India</strong>
                    </p>
                  </div>
                  <div className="mt-2 ">
                    <p> <strong>Company : </strong> Skylark Feeds Pvt. Ltd.</p>
                    <p> <strong>Experience : </strong> 1-5 years in poultry feed sales </p>
                    <p> <strong>Education : </strong> Any graduate
                    </p>
                    <span
                      className="pr-color font-weight-bold "
                      style={{ cursor: "pointer" }}
                      onClick={() => ApplyNow('Manager/ ASM/ Executive Sales - Broiler Feed Sales')}
                    >
                      Apply now
                    </span>
                  </div>
                </div>
              </>
            ) : null
          }
        </div>
      </div>
    </div>
  );
};

export default CurrentHoldings;
