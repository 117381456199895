import React from "react";
import "../../assests/styles/Home/home.css";
import Egg from "../../assests//images/egg.png";
import crackedEgg from "../../assests/images/crackedEgg.png";

function Home() {

  function testing() {
    document.querySelector("html").style.scrollBehavior = "smooth";
    document.querySelector(".egg img").src = crackedEgg;
    setTimeout(() => {
      const position = document.querySelector(".business").offsetTop;
      window.scrollTo(0, position - 100);
    }, 300);
    setTimeout(() => {
      document.querySelector(".egg img").src = Egg;
      document.querySelector("html").style.scrollBehavior = "initial";
    }, 700);
  }



  return (
    <div>
      <div className="home-header">
        <h1 className="px-2" >
          Prospering through <span>integration</span>
        </h1>
        <div
          className="header-sub mt-2 px-3"
          
        >
          <h6 className="mx-auto px-2">
            Skylark is the largest producer of poultry, equipped with the latest
            mechanized farming methods. It encompasses a diverse range of
            operations ranging from Grand Parent farming to large-scale Farming.
          </h6>
        </div>
        <div className="egg" onClick={() => testing()}>
          <img src={Egg} alt="Egg" style={{ transition: "all 0.1s" }} />
          <div>click to open</div>
        </div>
      </div>
    </div>
  );
}

export default Home;
