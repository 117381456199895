import React from "react";
import "../../assests/styles/About/timeline.css";
import { Timelines } from "../../configs/constants";

const Timeline = () => {
	return (
		<div className="timeline-container">
			<div className="timeline-main">
				<div className="section-head pb-3" data-aos="fade-up">
					<h1 className="h">Our Journey</h1>
					<h4>Celeberating 50 Years of glory in the Hatcheries era</h4>
				</div>
				<div className="timeline" data-aos="fade-up" data-aos-delay="100">
					<ul className="cbp_tmtimeline ">
						{Timelines.map((item) => {
							return (
								<li key={item.id}>
									<time className="cbp_tmtime">
										<span>{item.year}</span>
									</time>
									<div
										className="cbp_tmicon cbp_tmicon-phone"
										style={{
											backgroundImage: `url(/icons/${item.icon}.png)`,
										}}
									></div>
									<div className="cbp_tmlabel">
										{item.description.length > 1 ? (
											<p>
												<ul>
													{item.description.map((subItem, idx) => (
														<li key={idx}>{subItem}</li>
													))}
												</ul>
											</p>
										) : (
											<p>{item.description[0]}</p>
										)}
									</div>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Timeline;
