import React from "react";
import career from "../../assests/images/career.png";

const Banner = () => {
  return (
    <div className="pt-5">
      <div className=" pt-3">
        <div className="row flex justify-content-between flex-column-reverse flex-md-row align-items-center ">
          <div
            className="col-md-6 col-lg-6 pt-5 left-banner-section"
            data-aos="fade-right"
            data-aos-delay="200"
          >
            <h1 className="banner-heading">Become a part of our team !</h1>
            <p className="mt-4">
              One of the leading poultry brands  in India, Through an unwavering commitment towards quality,
              excellence, and strategic integration since 1985, has emerged into a full-fledged poultry enterprise of its own.
            </p>
            <p className="mt-2">
              To become a part of skylark and its core values along with the company vision, join us now
            </p>
          </div>
          <div
            className="col-sm-6 col-lg-5 text-center text-md-start  relative  px-xl-5 mx-auto mx-lg-0"
            data-aos="fade-up"
          >
            <img src={career} alt="" className="img-fluid career-banner-img" />
          </div>
        </div>

        <hr className="mt-4 mt-md-5" />
      </div>
    </div>
  );
};

export default Banner;
